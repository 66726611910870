import React, {useContext, useEffect, useState} from "react";
import '../CSS/DesignTwo/DesignTwoPageFour.css';
import {designTwoFavoriteInputsArray, designTwoMainCategories} from "./DesignTwoValues.jsx";
import {APIdatacall} from "../AppValues.jsx";
import NotFoundImage from "../images/NotFoundImage.png"
import ImageDirections from "../images/ImageDirections.png"
import LinkCount from "../Home/LinkCount";


function DesignTwoPageFour() {

    // eslint-disable-next-line
    const {APIdata, setAPIdata} = useContext(APIdatacall);

    const [animationhappened, setAnimationhappened] = useState(false);
    const [animationfourthpage, setAnimationfourthpage] = useState({
        left: {
            animation: 'x 0.3s',
            animationName: 'fadeInContent'
        }, right: {animation: 'x 0.4s', animationName: 'fadeInContent'}
    });

    function animationchange() {
        if (animationhappened === false) {
            setAnimationhappened(true)
            setAnimationfourthpage({
                left: {animation: '', animationName: ''},
                right: {animation: '', animationName: ''}
            })
        }
    }


// eslint-disable-next-line
    const {designTwoFavoriteInputs, setDesignTwoFavoriteInputs} = useContext(designTwoFavoriteInputsArray);
    // eslint-disable-next-line
    const {designTwoCategories, setDesignTwoCategories} = useContext(designTwoMainCategories);


    var cityexplorertext;
    if (APIdata.styles.generalpage.cityexplorertext !== null) {
        cityexplorertext = APIdata.styles.generalpage.cityexplorertext;
    } else {
        cityexplorertext = "Stadsontdekker " + APIdata.cityname;
    }

    var fourpageheaderleft;
    if (APIdata.styles.pagefour.headerleft !== null) {
        fourpageheaderleft = APIdata.styles.pagefour.headerleft;
    } else {
        fourpageheaderleft = "Jouw hotspots in " + APIdata.cityname;
    }


    var fourpagesubheaderleft;
    if (APIdata.styles.pagefour.subheaderleft !== null) {
        fourpagesubheaderleft = APIdata.styles.pagefour.subheaderleft;
    } else {
        fourpagesubheaderleft = "Gebaseerd op jouw voorkeuren hebben we de beste matches gevonden. Deze matches zijn gemaakt met behulp van lokale reviews van digitale look-a-likes.";
    }

    var fourpagetoplocationtext;
    if (APIdata.styles.pagefour.top.toptext !== null) {
        fourpagetoplocationtext = APIdata.styles.pagefour.top.toptext;
    } else {
        fourpagetoplocationtext = "Tip";
    }

    var toppositionmargintopregularcalculation;
    if (APIdata.styles.pagefour.top.toppositionmargintopregular !== null) {
        toppositionmargintopregularcalculation = (-1 * APIdata.styles.pagefour.top.toppositionmargintopregular) - 30;
    } else {
        toppositionmargintopregularcalculation = null;
    }

    var toppositionmargintopframecalculation;
    if (APIdata.styles.pagefour.top.toppositionmargintopregular !== null) {
        toppositionmargintopframecalculation = (-1 * APIdata.styles.pagefour.top.toppositionmargintopframe) - 30;
    } else {
        toppositionmargintopframecalculation = null;
    }


    const [fontheightheader, setFontheightheader] = useState("");
    const [fontheightsubheader, setFontheightsubheader] = useState("");
    const [lineheightsubheader, setLineheightsubheader] = useState("");
    const [stadsontdekkersize, setStadsontdekkersize] = useState("");
    const [stadsontdekkermapsuntoldfontsize, setStadsontdekkermapsuntoldfontsize] = useState("");
    const [stadsontdekkermapsuntoldmargintop, setStadsontdekkermapsuntoldmargintop] = useState("");
    const [stadsontdekkertextmargintop, setStadsontdekkertextmargintop] = useState("");
    const [rightcategoryheaderfontsize, setRightcategoryheaderfontsize] = useState("");
    const [rightcategorysubheaderfontsize, setRightcategorysubheaderfontsize] = useState("");
    const [rightsubmitbuttonfontsize, setRightsubmitbuttonfontsize] = useState("");
    const [rightsubcategoryfontsize, setRightsubcategoryfontsize] = useState("");
    const [rightsubmitfeedbackfontsize, setRightsubmitfeedbackfontsize] = useState("");


    //WIDTH FIX START
    const [widthhappened, setWidthhappened] = useState(false)

    function getWindowDimensions() {
        const {innerWidth: width} = window;
        return {
            width,
        };
    }

    function handleResize() {
        var newwidth = (getWindowDimensions());
        if (newwidth.width >= 1024) {
            setFontheightheader(APIdata.styles.pagefour.headerleftfontsizenormal);
            setFontheightsubheader(APIdata.styles.pagefour.subheaderleftfontsizenormal);
            setLineheightsubheader(APIdata.styles.pagefour.subheaderleftlineheightnormal);
            setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizenormal);
            setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizenormal);
            setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintopnormal);
            setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintopnormal);
            setRightcategoryheaderfontsize(APIdata.styles.pagefour.rightcategoryheaderfontsizenormal);
            setRightcategorysubheaderfontsize(APIdata.styles.pagefour.rightcategorysubheaderfontsizenormal);
            setRightsubcategoryfontsize(APIdata.styles.pagefour.rightsubcategoryfontsizenormal);
            setRightsubmitbuttonfontsize(APIdata.styles.pagefour.rightsubmitbuttonfontsizenormal);
            setRightsubmitfeedbackfontsize(APIdata.styles.pagefour.rightsubmitfeedbackfontsizenormal);
        } else if (newwidth.width >= 720 && newwidth.width < 1024) {
            setFontheightheader(APIdata.styles.pagefour.headerleftfontsizetablet);
            setFontheightsubheader(APIdata.styles.pagefour.subheaderleftfontsizetablet);
            setLineheightsubheader(APIdata.styles.pagefour.subheaderleftlineheighttablet);
            setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizetablet);
            setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizetablet);
            setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintoptablet);
            setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintoptablet);
            setRightcategoryheaderfontsize(APIdata.styles.pagefour.rightcategoryheaderfontsizetablet);
            setRightcategorysubheaderfontsize(APIdata.styles.pagefour.rightcategorysubheaderfontsizetablet);
            setRightsubcategoryfontsize(APIdata.styles.pagefour.rightsubcategoryfontsizetablet);
            setRightsubmitbuttonfontsize(APIdata.styles.pagefour.rightsubmitbuttonfontsizetablet);
            setRightsubmitfeedbackfontsize(APIdata.styles.pagefour.rightsubmitfeedbackfontsizetablet);
        } else if (newwidth.width < 720) {
            setFontheightheader(APIdata.styles.pagefour.headerleftfontsizemobile);
            setFontheightsubheader(APIdata.styles.pagefour.subheaderleftfontsizemobile);
            setLineheightsubheader(APIdata.styles.pagefour.subheaderleftlineheightmobile);
            setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizemobile);
            setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizemobile);
            setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintopmobile);
            setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintopmobile);
            setRightcategoryheaderfontsize(APIdata.styles.pagefour.rightcategoryheaderfontsizetablet);
            setRightcategorysubheaderfontsize(APIdata.styles.pagefour.rightcategorysubheaderfontsizetablet);
            setRightsubcategoryfontsize(APIdata.styles.pagefour.rightsubcategoryfontsizetablet);
            setRightsubmitbuttonfontsize(APIdata.styles.pagefour.rightsubmitbuttonfontsizemobile);
            setRightsubmitfeedbackfontsize(APIdata.styles.pagefour.rightsubmitfeedbackfontsizemobile);
        }
    }

    if (widthhappened === false) {
        handleResize()
        setWidthhappened(true)
    }
    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    //WIDTH FIX END


    const [apicallshappened, setApicallshappened] = useState(false);
    const [subcategories, setSubcategories] = useState([]);
    const [recos, setRecos] = useState([]);

    async function getRecosAPI() {
        var stringforapicall = ''

        for (let i = 0; i < designTwoFavoriteInputs.length; i++) {
            stringforapicall = stringforapicall + '&favoritesid=' + designTwoFavoriteInputs[i].favoriteid;
        }
        // const urlreconojson = await fetch('https://api-mapsuntold.nl/recommendations?favoritesid=arrayitem' + stringforapicall + '&city=' + APIdata.city + '&client=' + APIdata.clientname);
        const urlreconojson = await fetch('https://api-mapsuntold.nl/recommendations?favoritesid=arrayitem' + stringforapicall + '&city=' + APIdata.city + '&client=' + APIdata.clientname);
        var urlreco = await urlreconojson.json();
        recosToList(urlreco);
        setRecos(urlreco);
    }

    function recosToList(urlreco) {
        var listsubcategories = [];
        for (let i = 0; i < urlreco.length; i++) {
            var subcategories = [];
            for (let j = 0; j < urlreco[i].recos.length; j++) {
                subcategories.push(urlreco[i].recos[j].subcategory)
            }
            listsubcategories.push({indexvalue: urlreco[i].indexvalue, subcategories: subcategories, status: 0})
        }
        setSubcategories(listsubcategories)
    }

    if (apicallshappened === false) {
        getRecosAPI();
        setApicallshappened(true)
    }

    function clickedonsubcategory(event) {

        var indexvalue = event.target.id.split(' ')[0]
        var subcategory = event.target.id.split(' ')[1]
        var listtobeexported = [];

        for (let i = 0; i < subcategories.length; i++) {

            if (subcategories[i].indexvalue === indexvalue) {
                listtobeexported.push({
                    indexvalue: indexvalue,
                    subcategories: subcategories[i].subcategories,
                    status: parseInt(subcategory)
                })
            } else (
                listtobeexported.push(subcategories[i])
            )
        }
        setSubcategories(listtobeexported)
    }


    function scrollfunction(event) {
        var indexvalue = event.target.id.split(' ')[0];
        var direction = event.target.id.split(' ')[1];
        var index = event.target.id.split(' ')[2];
        var id = indexvalue + " indexvaluerecos " + index;
        setTimeout(() => {

            const outsider = document.getElementById(id);
            const distance = 630;

            function scrollLeft() {
                outsider.scrollBy({
                    left: -distance,
                    behavior: 'smooth'
                });
            }

            function scrollRight() {
                outsider.scrollBy({
                    left: distance,
                    behavior: 'smooth'
                });
            }

            if (direction === "indexvalueback") {
                scrollLeft();
            } else {
                scrollRight();
            }
        }, 0);
    }

    function MouseOver(event) {
        var indexvalue = event.target.id.split(' ')[0];
        var direction = event.target.id.split(' ')[1];
        var index = event.target.id.split(' ')[2];
        var id = indexvalue + " " + direction + " " + index + " arrow";
        document.getElementById(id).style.borderColor = "white";
        document.getElementById(indexvalue + " " + direction + " " + index).style.backgroundColor = APIdata.styles.generalpage.colorone;
    }

    function MouseOut(event) {
        var indexvalue = event.target.id.split(' ')[0];
        var direction = event.target.id.split(' ')[1];
        var index = event.target.id.split(' ')[2];
        var id = indexvalue + " " + direction + " " + index + " arrow";
        document.getElementById(id).style.borderColor = APIdata.styles.generalpage.colorone;
        document.getElementById(indexvalue + " " + direction + " " + index).style.backgroundColor = "transparent";
    }


    const [feedbacktextsection, setFeedbacktextsection] = useState("none");
    const [feedbackpaddingbottom, setFeedbackpaddingbottom] = useState(200);
    const [feedbackhappened, setFeedbackhappened] = useState(false);
    const [feedbackidnumber, setFeedbackidnumber] = useState(0);
    const [feedbacksubmitted, setFeedbacksubmitted] = useState(false);

    var hoverstatevar = {
        one: "grayscale(70%)",
        transone: "none",
        two: "grayscale(70%)",
        three: "grayscale(70%)",
        four: "grayscale(70%)",
        five: "grayscale(70%)"
    }

    function Feedback() {

        if (feedbackhappened === true) {
            if (feedbackidnumber === "1") {
                hoverstatevar = {
                    one: "grayscale(0)",
                    transone: "x 1s",
                    transonename: "design-two-page-four-feedbacksmiley",
                    two: "grayscale(70%)",
                    three: "grayscale(70%)",
                    four: "grayscale(70%)",
                    five: "grayscale(70%)"
                }
            } else if (feedbackidnumber === "2") {
                hoverstatevar = {
                    one: "grayscale(0)",
                    two: "grayscale(0)",
                    transtwo: "x 1s",
                    transtwoname: "design-two-page-four-feedbacksmiley",
                    three: "grayscale(70%)",
                    four: "grayscale(70%)",
                    five: "grayscale(70%)"
                }
            } else if (feedbackidnumber === "3") {
                hoverstatevar = {
                    one: "grayscale(0)",
                    two: "grayscale(0)",
                    three: "grayscale(0)",
                    transthree: "x 1s",
                    transthreename: "design-two-page-four-feedbacksmiley",
                    four: "grayscale(70%)",
                    five: "grayscale(70%)"
                }
            } else if (feedbackidnumber === "4") {
                hoverstatevar = {
                    one: "grayscale(0)",
                    two: "grayscale(0)",
                    three: "grayscale(0)",
                    four: "grayscale(0)",
                    transfour: "x 1s",
                    transfourname: "design-two-page-four-feedbacksmiley",
                    five: "grayscale(70%)"
                }
            } else if (feedbackidnumber === "5") {
                hoverstatevar = {
                    one: "grayscale(0)",
                    two: "grayscale(0)",
                    three: "grayscale(0)",
                    four: "grayscale(0)",
                    five: "grayscale(0)",
                    transfive: "x 1s",
                    transfivename: "design-two-page-four-feedbacksmiley"
                }
            }
        }

        const [hoverstate, setHoverstate] = useState(hoverstatevar);
        const [feedbacktextvalue, setFeedbacktextvalue] = useState("");

        function scroll() {
            const section = document.querySelector('#feedbacktext');
            section.scrollIntoView({behavior: 'smooth', block: 'start'});
        }

        async function feedbackclick(event) {
            var feedbackid = event.target.id.split(' ')[1];
            if (feedbackhappened === false) {

                setFeedbackhappened(true)
                setFeedbackidnumber(feedbackid)

                if (feedbackid === "1" || feedbackid === "2" || feedbackid === "3") {

                    setFeedbackpaddingbottom(500)
                    setFeedbacktextsection("block")
                    setTimeout(() => {
                        window.document.getElementById("feedbacktextarea").focus();
                    }, 200);
                    setTimeout(() => {
                        scroll()
                    }, 200);
                }
                if (feedbackid === "4" || feedbackid === "5") {
                    setFeedbacksubmitted(true);
                }
            }
            // eslint-disable-next-line
            const feedbacknojson = await fetch('https://api-mapsuntold.nl/smileyfeedback?rating=' + feedbackid + '&clientname=' + APIdata.clientname + '&feedbacktype=rating');
        }

        function feedbackhover(event) {
            if (feedbackhappened === false) {
                var feedbackidhover = event.target.id.split(' ')[1];

                if (feedbackidhover === "1") {
                    setHoverstate({
                        one: "grayscale(0)",
                        two: "grayscale(70%)",
                        three: "grayscale(70%)",
                        four: "grayscale(70%)",
                        five: "grayscale(70%)"
                    })
                } else if (feedbackidhover === "2") {
                    setHoverstate({
                        one: "grayscale(0)",
                        two: "grayscale(0)",
                        three: "grayscale(70%)",
                        four: "grayscale(70%)",
                        five: "grayscale(70%)"
                    })
                } else if (feedbackidhover === "3") {
                    setHoverstate({
                        one: "grayscale(0)",
                        two: "grayscale(0)",
                        three: "grayscale(0)",
                        four: "grayscale(70%)",
                        five: "grayscale(70%)"
                    })
                } else if (feedbackidhover === "4") {
                    setHoverstate({
                        one: "grayscale(0)",
                        two: "grayscale(0)",
                        three: "grayscale(0)",
                        four: "grayscale(0)",
                        five: "grayscale(70%)"
                    })
                } else if (feedbackidhover === "5") {
                    setHoverstate({
                        one: "grayscale(0)",
                        two: "grayscale(0)",
                        three: "grayscale(0)",
                        four: "grayscale(0)",
                        five: "grayscale(0)"
                    })
                }
            }
        }

        function feedbackonchange(event) {
            setFeedbacktextvalue(event.target.value);

        }

        async function givefeedback() {
            document.getElementById("feedbacktextarea").value = "";
            setFeedbacktextsection("none");
            setFeedbackpaddingbottom(200);
            setFeedbacksubmitted(true);
            // eslint-disable-next-line
            const feedbacknojson = await fetch('https://api-mapsuntold.nl/smileyfeedback?rating=' + feedbackidnumber + '&clientname=' + APIdata.clientname + '&feedbacktype=text&text=' + feedbacktextvalue);

        }

        return (
            <div className="design-two-page-four-feedback-container" style={{paddingBottom: feedbackpaddingbottom}}>
                <div className="design-two-page-four-feedback-subcontainer">
                    <div className="design-two-page-four-feedback-question"
                         style={{
                             fontFamily: APIdata.styles.pagefour.rightsubmitfeedbackfont,
                             fontSize: rightsubmitfeedbackfontsize,
                             marginBottom: 20
                         }}>Wat vond je van de hotspots?
                    </div>
                    <div id="feedback 1" className="design-two-page-four-feedback-feedbackbuttons"
                         onClick={feedbackclick} onMouseEnter={feedbackhover}>
                        <img id="feedback 1 image" src="DesignTwo/FeedbackImages/smiley 1 color.png" alt="img"
                             className="design-two-page-four-feedback-feedbackbuttons-image" style={{
                            filter: hoverstate.one,
                            animation: hoverstate.transone,
                            animationName: hoverstate.transonename
                        }}>
                        </img>
                    </div>
                    <div id="feedback 2" className="design-two-page-four-feedback-feedbackbuttons"
                         onClick={feedbackclick} onMouseEnter={feedbackhover}>
                        <img id="feedback 2 image" src="DesignTwo/FeedbackImages/smiley 2 color.png" alt="img"
                             className="design-two-page-four-feedback-feedbackbuttons-image" style={{
                            filter: hoverstate.two,
                            animation: hoverstate.transtwo,
                            animationName: hoverstate.transtwoname
                        }}>
                        </img>
                    </div>
                    <div id="feedback 3" className="design-two-page-four-feedback-feedbackbuttons"
                         onClick={feedbackclick} onMouseEnter={feedbackhover}>
                        <img id="feedback 3 image" src="DesignTwo/FeedbackImages/smiley 3 color.png" alt="img"
                             className="design-two-page-four-feedback-feedbackbuttons-image" style={{
                            filter: hoverstate.three,
                            animation: hoverstate.transthree,
                            animationName: hoverstate.transthreename
                        }}>
                        </img>
                    </div>
                    <div id="feedback 4" className="design-two-page-four-feedback-feedbackbuttons"
                         onClick={feedbackclick} onMouseEnter={feedbackhover}>
                        <img id="feedback 4 image" src="DesignTwo/FeedbackImages/smiley 4 color.png" alt="img"
                             className="design-two-page-four-feedback-feedbackbuttons-image" style={{
                            filter: hoverstate.four,
                            animation: hoverstate.transfour,
                            animationName: hoverstate.transfourname
                        }}>
                        </img>
                    </div>
                    <div id="feedback 5" className="design-two-page-four-feedback-feedbackbuttons"
                         onClick={feedbackclick} onMouseEnter={feedbackhover}>
                        <img id="feedback 5 image" src="DesignTwo/FeedbackImages/smiley 5 color.png" alt="img"
                             className="design-two-page-four-feedback-feedbackbuttons-image" style={{
                            filter: hoverstate.five,
                            animation: hoverstate.transfive,
                            animationName: hoverstate.transfivename
                        }}>
                        </img>
                    </div>
                    <div id="feedbacktext" className="design-two-page-four-feedback-textcontainer"
                         style={{display: feedbacktextsection}}>
            <textarea id="feedbacktextarea" className="design-two-page-four-feedback-textcontainer-input"
                      type="textarea" rows={5} cols={5} onChange={feedbackonchange}
                      placeholder="Typ hier wat je vindt wat beter kan"
                      style={{
                          fontFamily: APIdata.styles.pagefour.rightsubmitfeedbackfont,
                          fontSize: rightsubmitfeedbackfontsize
                      }}
            ></textarea>
                        <div onClick={givefeedback}
                             className="design-two-page-four-feedback-textcontainer-submit-button"
                             style={{
                                 fontFamily: APIdata.styles.pagefour.rightsubmitbuttonfont,
                                 fontSize: rightsubmitbuttonfontsize,
                                 borderColor: APIdata.styles.pagefour.rightsubmitbuttonbordercolor,
                                 color: APIdata.styles.pagefour.rightsubmitbuttoncolor
                             }}>Verstuur
                        </div>
                    </div>
                    {feedbacksubmitted === true ?
                        <div className="design-two-page-four-feedback-thanks" style={{
                            fontFamily: APIdata.styles.pagefour.rightsubmitbuttonfont,
                            fontSize: rightsubmitbuttonfontsize,
                            color: APIdata.styles.pagefour.rightsubmitbuttoncolor
                        }}>dankje!</div>
                        : null}
                </div>
            </div>
        )
    }


    function RecommendationsDisplay() {

        return (

            <div className="design-two-page-four-right-recommendations-container"
                 style={{
                     animation: animationfourthpage.right.animation,
                     animationName: animationfourthpage.right.animationName,
                     fontSize: 12
                 }}>


                {recos.length === 0 ?
                    <div className="design-two-page-four-lds-dual-ring"></div>
                    :
                    null}

                {subcategories.map((item, index) => (
                    <div key={index}>

                        <div className="design-two-page-four-subcategories-recos-parentcategory"
                             style={{
                                 fontFamily: APIdata.styles.pagefour.rightcategoryheaderfont,
                                 fontSize: rightcategoryheaderfontsize
                             }}>
                            {APIdata.categories[item.indexvalue].parentcategory}
                        </div>
                        <div className="design-two-page-four-subcategories-recos-category-description"
                             style={{
                                 fontFamily: APIdata.styles.pagefour.rightcategorysubheaderfont,
                                 fontSize: rightcategorysubheaderfontsize
                             }}>
                            {APIdata.categories[item.indexvalue].categoryrecodescription}
                        </div>

                        {item.subcategories.map((itemtwo, indexx) => (
                            <div className="design-two-page-four-subcategories-recos-container" key={(56789 + indexx)}>
                                {item.status === indexx ?
                                    <div className="design-two-page-four-right-subcategory-selected" style={{
                                        backgroundColor: APIdata.styles.generalpage.colorone,
                                        borderColor: APIdata.styles.generalpage.colorone,
                                        fontFamily: APIdata.styles.pagefour.rightsubcategoryfont,
                                        fontSize: rightsubcategoryfontsize
                                    }}>
                                        {itemtwo}
                                    </div>
                                    :
                                    <div className="design-two-page-four-right-subcategory-not-selected" style={{
                                        color: APIdata.styles.generalpage.colorone,
                                        borderColor: APIdata.styles.generalpage.colorone,
                                        fontFamily: APIdata.styles.pagefour.rightsubcategoryfont,
                                        fontSize: rightsubcategoryfontsize
                                    }}
                                         id={item.indexvalue + " " + indexx}
                                         onClick={clickedonsubcategory}>
                                        {itemtwo}
                                    </div>
                                }
                            </div>
                        ))}
                        <div key={index + 43210987654321} className="design-two-page-four-arrows-container">
                            <div id={item.indexvalue + " indexvalueback " + item.status}
                                 style={{fontSize: 15, borderColor: APIdata.styles.generalpage.colorone}}
                                 onMouseOver={MouseOver} onMouseOut={MouseOut} onClick={scrollfunction}
                                 className="design-two-page-four-arrow-container">
                                <div className="design-two-page-four-arrow-left" onMouseOver={MouseOver}
                                     onMouseOut={MouseOut} onClick={scrollfunction}
                                     id={item.indexvalue + " indexvalueback " + item.status + " arrow"}
                                     style={{borderColor: APIdata.styles.generalpage.colorone}}></div>
                            </div>
                            <div id={item.indexvalue + " indexvaluenext " + item.status}
                                 style={{fontSize: 15, borderColor: APIdata.styles.generalpage.colorone}}
                                 onMouseOver={MouseOver} onMouseOut={MouseOut} onClick={scrollfunction}
                                 className="design-two-page-four-arrow-container">
                                <div className="design-two-page-four-arrow-right" onMouseOver={MouseOver}
                                     onMouseOut={MouseOut} onClick={scrollfunction}
                                     id={item.indexvalue + " indexvaluenext " + item.status + " arrow"}
                                     style={{borderColor: APIdata.styles.generalpage.colorone}}></div>
                            </div>
                        </div>

                        {item.subcategories.map((itemtwo, indexx) => (
                            <div className="design-two-page-four-recos-container" key={indexx + 936298758}
                                 id={item.indexvalue + " indexvaluerecos " + indexx}>

                                {item.status === indexx ?
                                    <div>
                                        {recos[index].recos[item.status].recos.map((itemthree, indexxx) => (
                                            <div key={(12345 + indexxx)} style={{
                                                backgroundColor: "#f7f7f7",
                                                display: "inline-block",
                                                verticalAlign: "top"
                                            }}>
                                                {itemthree.inlijsting === 0 ?
                                                    <div className="design-two-page-four-reco-card">
                                                        <div className="design-two-page-four-reco-card-image-outer-div"
                                                             style={{
                                                                 backgroundImage: `url(${NotFoundImage})`,
                                                                 backgroundRepeat: "no-repeat",
                                                                 backgroundSize: "cover",
                                                                 backgroundPosition: "center",
                                                                 borderTopLeftRadius: 0,
                                                                 borderTopRightRadius: 0
                                                             }}>
                                                            <div className="design-two-page-four-reco-card-image-div"
                                                                 style={{
                                                                     backgroundImage: `url(${itemthree.imageurl})`,
                                                                     backgroundRepeat: "no-repeat",
                                                                     backgroundSize: "cover",
                                                                     backgroundPosition: "center",
                                                                     borderTopLeftRadius: 0,
                                                                     borderTopRightRadius: 0
                                                                 }}>
                                                            </div>
                                                        </div>
                                                        <div className="design-two-page-four-reco-card-score"
                                                             style={{backgroundColor: APIdata.styles.generalpage.colortwo}}>
                                                            {itemthree.score}%
                                                        </div>
                                                        {itemthree.toplocation === "0" ?
                                                            // eslint-disable-next-line
                                                            <div className="design-two-page-four-reco-card-top" style={{
                                                                marginLeft: APIdata.styles.pagefour.top.toppositionmarginleftregular,
                                                                marginTop: APIdata.styles.pagefour.top.toppositionmargintopregular,
                                                                marginBottom: toppositionmargintopregularcalculation,
                                                                backgroundColor: APIdata.styles.pagefour.top.topbackgroundcolor,
                                                                backgroundImage: `url("` + APIdata.styles.pagefour.top.topbackgroundimage + `")`,
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundSize: "cover",
                                                                backgroundPosition: "center"
                                                            }}>
                                                                <div
                                                                    className="design-two-page-four-reco-card-top-text">
                                                                    {fourpagetoplocationtext}
                                                                </div>
                                                            </div>
                                                            : null}
                                                        <div className="design-two-page-four-reco-card-title">
                                                            {itemthree.title}
                                                        </div>
                                                        <div className="design-two-page-four-reco-card-category">
                                                            {itemthree.category}
                                                        </div>
                                                        <LinkCount origin="design-two-page-four-reco-card-website"
                                                                   href={"https://" + itemthree.website} target="_blank"
                                                                   rel="noreferrer">
                                                            <div className="design-two-page-four-reco-card-website"
                                                                 style={{color: APIdata.styles.generalpage.colorone}}>
                                                                Website
                                                            </div>
                                                        </LinkCount>
                                                        <LinkCount origin="design-two-page-four-reco-card-address"
                                                                   href={"https://maps.google.com/?q=" + itemthree.address}
                                                                   target="_blank" rel="noreferrer">
                                                            <div className="design-two-page-four-reco-card-address"
                                                                 style={{backgroundColor: APIdata.styles.generalpage.colorone}}>
                                                                <div
                                                                    className="design-two-page-four-reco-card-address-image"
                                                                    style={{
                                                                        backgroundImage: `url(${ImageDirections})`,
                                                                        backgroundRepeat: "no-repeat",
                                                                        backgroundSize: "cover",
                                                                        backgroundPosition: "center"
                                                                    }}>
                                                                </div>
                                                            </div>
                                                        </LinkCount>
                                                    </div>
                                                    :
                                                    <div className="design-two-page-four-reco-card-list" style={{
                                                        backgroundColor: APIdata.styles.pagefour.frames[itemthree.inlijsting - 1].backgroundcolor,
                                                        backgroundImage: `url(${APIdata.styles.pagefour.frames[itemthree.inlijsting - 1].image})`,
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundSize: "cover",
                                                        backgroundPosition: "center",
                                                        borderTopLeftRadius: 0,
                                                        borderTopRightRadius: 0
                                                    }}>
                                                        <div
                                                            className="design-two-page-four-reco-card-image-outer-div-list"
                                                            style={{
                                                                backgroundImage: `url(${NotFoundImage})`,
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundSize: "cover",
                                                                backgroundPosition: "center",
                                                                borderTopLeftRadius: 0,
                                                                borderTopRightRadius: 0
                                                            }}>
                                                            <div
                                                                className="design-two-page-four-reco-card-image-div-list"
                                                                style={{
                                                                    backgroundImage: `url(${itemthree.imageurl})`,
                                                                    backgroundRepeat: "no-repeat",
                                                                    backgroundSize: "cover",
                                                                    backgroundPosition: "center",
                                                                    borderTopLeftRadius: 0,
                                                                    borderTopRightRadius: 0
                                                                }}>
                                                            </div>
                                                        </div>
                                                        <div className="design-two-page-four-reco-card-score-list"
                                                             style={{backgroundColor: APIdata.styles.generalpage.colortwo}}>
                                                            {itemthree.score}%
                                                        </div>
                                                        {itemthree.toplocation === "0" ?
                                                            // eslint-disable-next-line
                                                            <div className="design-two-page-four-reco-card-top-list"
                                                                 style={{
                                                                     marginLeft: APIdata.styles.pagefour.top.toppositionmarginleftframe,
                                                                     marginTop: APIdata.styles.pagefour.top.toppositionmargintopframe,
                                                                     marginBottom: toppositionmargintopframecalculation,
                                                                     backgroundColor: APIdata.styles.pagefour.top.topbackgroundcolor,
                                                                     backgroundImage: `url("` + APIdata.styles.pagefour.top.topbackgroundimage + `")`,
                                                                     backgroundRepeat: "no-repeat",
                                                                     backgroundSize: "cover",
                                                                     backgroundPosition: "center"
                                                                 }}>
                                                                <div
                                                                    className="design-two-page-four-reco-card-top-text-list">
                                                                    {fourpagetoplocationtext}
                                                                </div>
                                                            </div>
                                                            : null}
                                                        <div className="design-two-page-four-reco-card-content-list">
                                                            <div className="design-two-page-four-reco-card-title-list">
                                                                {itemthree.title}
                                                            </div>
                                                            <div
                                                                className="design-two-page-four-reco-card-category-list">
                                                                {itemthree.category}
                                                            </div>
                                                            <LinkCount
                                                                origin="design-two-page-four-reco-card-website-list"
                                                                href={"https://" + itemthree.website} target="_blank"
                                                                rel="noreferrer">
                                                                <div
                                                                    className="design-two-page-four-reco-card-website-list"
                                                                    style={{color: APIdata.styles.generalpage.colorone}}>
                                                                    Website
                                                                </div>
                                                            </LinkCount>
                                                            <LinkCount
                                                                origin="design-two-page-four-reco-card-address-list"
                                                                href={"https://maps.google.com/?q=" + itemthree.address}
                                                                target="_blank" rel="noreferrer">
                                                                <div
                                                                    className="design-two-page-four-reco-card-address-list"
                                                                    style={{backgroundColor: APIdata.styles.generalpage.colorone}}>
                                                                    <div
                                                                        className="design-two-page-four-reco-card-address-image-list"
                                                                        style={{
                                                                            backgroundImage: `url(${ImageDirections})`,
                                                                            backgroundRepeat: "no-repeat",
                                                                            backgroundSize: "cover",
                                                                            backgroundPosition: "center"
                                                                        }}>
                                                                    </div>
                                                                </div>
                                                            </LinkCount>
                                                        </div>
                                                    </div>}
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        )
    }


    return (
        <div className="design-two-page-four-body" onClick={animationchange}>
            <div className="design-two-page-four-left-body"
                 style={{
                     backgroundColor: APIdata.styles.generalpage.leftcolor,
                 }}>

                <div className="design-two-page-four-left-cityexplorer"
                     style={{
                         marginTop: APIdata.styles.generalpage.bartopheight,
                         paddingTop: APIdata.styles.generalpage.citydiscoveryheight
                     }}>
                    <div className="design-two-page-four-left-cityexplorer-logo">
                        <img src={require("../images/maps-untold-logo-small.png")} alt="logo"
                             className="design-two-page-four-left-cityexplorer-logo-image"/>
                    </div>
                    <div className="design-two-page-four-left-cityexplorer-mapsuntold">
                        <div className="design-two-page-four-left-cityexplorer-mapsuntold-text"
                             style={{
                                 fontFamily: APIdata.styles.generalpage.stadsontdekkermapsuntoldfont,
                                 fontSize: stadsontdekkermapsuntoldfontsize,
                                 marginTop: stadsontdekkermapsuntoldmargintop
                             }}>
                            Maps Untold
                        </div>
                    </div>
                    <div className="design-two-page-four-left-cityexplorer-line">
                    </div>
                    <div className="design-two-page-four-left-cityexplorer-city">
                        <div className="design-two-page-four-left-cityexplorer-city-text"
                             style={{
                                 fontFamily: APIdata.styles.generalpage.stadsontdekkertextfont,
                                 color: APIdata.styles.generalpage.colorone,
                                 fontSize: stadsontdekkersize,
                                 marginTop: stadsontdekkertextmargintop
                             }}>
                            {cityexplorertext}
                        </div>
                    </div>
                </div>

                <div className="design-two-page-four-left-header"
                     style={{
                         fontFamily: APIdata.styles.generalpage.headerleftfont,
                         fontSize: fontheightheader,
                         color: APIdata.styles.generalpage.headerleftcolor,
                         animation: animationfourthpage.left.animation,
                         animationName: animationfourthpage.left.animationName,
                     }}>
                    {fourpageheaderleft}
                </div>
                <div className="design-two-page-four-left-subheader"
                     style={{
                         fontFamily: APIdata.styles.generalpage.subheaderleftfont,
                         fontSize: fontheightsubheader,
                         color: APIdata.styles.generalpage.subheaderleftcolor,
                         lineHeight: lineheightsubheader,
                         animation: animationfourthpage.left.animation,
                         animationName: animationfourthpage.left.animationName,

                     }}>
                    {fourpagesubheaderleft}

                </div>
            </div>
            <div className="design-two-page-four-right-body"
                 style={{
                     backgroundColor: APIdata.styles.generalpage.rightcolor,
                     paddingBottom: 100,
                 }}>
                <div className="design-two-page-four-right-first-container" style={{
                    marginTop: APIdata.styles.pagefour.rightmargintop,
                    paddingBottom: 100,
                }}>

                    <RecommendationsDisplay/>

                    {APIdata.styles.pagefour.feedbacksection === "true" ?
                        <Feedback/>
                        : null}

                </div>
            </div>
        </div>

    )
}

export default DesignTwoPageFour;
