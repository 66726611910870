import React, { useState, useEffect, useContext } from "react";
import '../CSS/DesignTwo/DesignTwoProgressBar.css';
import { designTwoPagecounter, designTwoMainCategories, designTwoFavoriteInputsArray } from "./DesignTwoValues.jsx";
import { APIdatacall } from "../AppValues.jsx";


function DesignTwoProgressBar() {

      // eslint-disable-next-line
      const { APIdata, setAPIdata } = useContext(APIdatacall);
      const { designTwoPage, setDesignTwoPage } =  useContext(designTwoPagecounter)

    const { designTwoCategories, setDesignTwoCategories } = useContext(designTwoMainCategories);
    const { designTwoFavoriteInputs, setDesignTwoFavoriteInputs } = useContext(designTwoFavoriteInputsArray);

    const [buttonfontsize, setButtonfontsize] = useState("");
    const [buttonmargintop, setButtonmargintop] = useState("");



    //WIDTH FIX START
    const [widthhappened, setWidthhappened] = useState(false)
    function getWindowDimensions() {
        const { innerWidth: width} = window;
        return {
          width,
        };
      }
      function handleResize() {
        var newwidth = (getWindowDimensions());
        if (newwidth.width >= 1024){
          setButtonfontsize(APIdata.styles.progressbar.progressbarbuttonsfontsizenormal);
          setButtonmargintop(APIdata.styles.progressbar.progressbarbuttonsmargintopnormal);
      } else if (newwidth.width >=720 && newwidth.width < 1024){
          setButtonfontsize(APIdata.styles.progressbar.progressbarbuttonsfontsizetablet);
          setButtonmargintop(APIdata.styles.progressbar.progressbarbuttonsmargintoptablet);
      } else if (newwidth.width < 720){        
          setButtonfontsize(APIdata.styles.progressbar.progressbarbuttonsfontsizemobile);
          setButtonmargintop(APIdata.styles.progressbar.progressbarbuttonsmargintopmobile);
      }}

      const [progresslinecolor, setProgresslinecolor] = useState();   
      function progresslinecolorfunction () {
      if (APIdata.styles.progressbar.progressbarlinecolor === undefined || APIdata.styles.progressbar.progressbarlinecolor === null){
          setProgresslinecolor(APIdata.styles.generalpage.colortwo)
      } else {
          setProgresslinecolor(APIdata.styles.progressbar.progressbarlinecolor)
      }}


      if ( widthhappened === false ) {
        handleResize()
        setWidthhappened(true)
        progresslinecolorfunction()
      }

    useEffect(() => {
        handleResize() 
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      });
    //WIDTH FIX END


    const [progresswidth, setProgresswidth] = useState("25%");   
    const [nextbuttontext, setNextButtonText] = useState("Volgende");   
    // eslint-disable-next-line 
    const [backbuttontext, setBackButtonText] = useState("Terug");   
    const [backbuttonstate, setBackButtonState] = useState("design-two-backbutton-inactive");   

    useEffect(() => {
        if (designTwoPage === 1){
            setProgresswidth("25%")
            if (backbuttonstate === "design-two-backbutton"){
                setBackButtonState("design-two-backbutton-transition")
            }
        } else if (designTwoPage === 2){
            setProgresswidth("50%")
            setBackButtonState("design-two-backbutton")
        } else if (designTwoPage === 3){
            setProgresswidth("75%")
            setNextButtonText("Volgende")
        } else if (designTwoPage === 4){
            setProgresswidth("100%")
            setNextButtonText("Opnieuw")
        }
    },[designTwoPage, backbuttonstate])

        function volgende () {
            if (designTwoPage < 5){
                if (designTwoPage !== 2 && designTwoPage !== 3 && designTwoPage !== 4){
            // eslint-disable-next-line
            var nextPage = designTwoPage + 1;
            setDesignTwoPage(nextPage)}

            if (designTwoPage === 2 && designTwoCategories.length !== 0){
                // eslint-disable-next-line
                var nextPage = designTwoPage + 1;
                setDesignTwoPage(nextPage)
            } else if (designTwoPage === 2 && designTwoCategories.length === 0) {
                alert("voer minimaal 1 category in")
            }

                if (designTwoPage === 3 && designTwoFavoriteInputs.length !== 0){
                    // eslint-disable-next-line
                    var nextPage = designTwoPage + 1;
                    setDesignTwoPage(nextPage)
                } else if (designTwoPage === 3 && designTwoFavoriteInputs.length === 0) {
                    alert("voer minimaal 1 favoriete plek in")
                }

                if (designTwoPage === 4){
                    setDesignTwoPage(2)
                    setNextButtonText("Volgende")
                    setDesignTwoCategories([])
                    setDesignTwoFavoriteInputs([])
                } 
                }
        }
        function vorige () {
            if (designTwoPage > 1){
            var lastPage = designTwoPage - 1;
            setDesignTwoPage(lastPage)}
        }

    return (
        <div className="design-two-progressbar"
        style={{
            backgroundColor: APIdata.styles.progressbar.progressbarbackgroundcolor
        }}>
        <div className="design-two-progressline"
        style={{
            width: progresswidth,
            backgroundColor: progresslinecolor
        }}>
        </div>
        <div className={backbuttonstate} onClick={vorige}
        style={{
            fontFamily: APIdata.styles.progressbar.progressbarbuttonsfont,
            fontSize: buttonfontsize,
            borderColor: APIdata.styles.progressbar.progressbarbordercolor
        }}>         
        <div className="design-two-backbutton-text"
        style={{
            fontFamily: APIdata.styles.progressbar.progressbarbuttonsfont,
            fontSize: buttonfontsize,
            top: buttonmargintop,
            color: APIdata.styles.progressbar.progressbarcolor
        }}>        
        {backbuttontext}
        </div>  
        </div>
        <div className="design-two-nextbutton" onClick={volgende}
        style={{
            backgroundColor: APIdata.styles.generalpage.colortwo,
            borderColor: APIdata.styles.generalpage.colortwo
        }}> 
        <div className="design-two-nextbutton-text"
        style={{
            fontFamily: APIdata.styles.progressbar.progressbarbuttonsfont,
            fontSize: buttonfontsize,
            top: buttonmargintop
        }}>        
        {nextbuttontext}
        </div>
        </div>
        <a href='../Files/Privacyverklaring Maps Untold.pdf' download target="_blank"
              style = {{ 
                position: "fixed",
                top: "100vh",
                marginTop: -15,
                zIndex: 3000,
                textDecorationLine : 'underline',
                color : 'gray',
                left: 5,
                cursor: 'pointer',                
                fontFamily: "montserrat",
                fontSize: 10,
              }}>
                Privacy Statement
              </a>
        </div>
    )
}

export default DesignTwoProgressBar;