import React, { useContext } from "react";
import DesignSixBarTop from "./DesignSix/DesignSixBarTop";
import DesignSixPageOne from './DesignSix/DesignSixPageOne.jsx';
import DesignSixPageTwo from './DesignSix/DesignSixPageTwo.jsx';
import DesignSixPageThree from './DesignSix/DesignSixPageThree.jsx';
import DesignSixPageFour from './DesignSix/DesignSixPageFour.jsx';
import DesignSixPageFive from './DesignSix/DesignSixPageFive.jsx';
import DesignSixProgressBar from "./DesignSix/DesignSixProgressBar.jsx";
import { designSixPagecounter } from "./DesignSix/DesignSixValues.jsx";


function designSix() {
    
        // eslint-disable-next-line
        const { designSixPage, setDesignSixPage } =  useContext(designSixPagecounter)
   
        return (
            <div>
            <DesignSixBarTop/>
            {designSixPage === 1 ? <DesignSixPageOne/> : null}
            {designSixPage === 2 ? <DesignSixPageTwo/> : null}
            {designSixPage === 3 ? <DesignSixPageThree/> : null}
            {designSixPage === 4 ? <DesignSixPageFour/> : null}
            {designSixPage === 5 ? <DesignSixPageFive/> : null}
            <DesignSixProgressBar/>
            </div>
        )
    }

export default designSix;