import React, { useContext }from "react";
import { clientdatacontext } from "../LoginValues.jsx";


function RecommendationsMinimalNumberOfRecos () {

    const { clientdata, setClientdata } = useContext(clientdatacontext);

    var listofnumbers = [];
    function makelist () {
    for (let i = 0; i < 101; i++) {
        listofnumbers.push(JSON.stringify(i))
    }}
    makelist ();

    async function numberofminimalrecosfunction (event){
        var newnumberofminimalrecos = event.target.id;
        // const urlnumberofminimalrecosnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=numberofminimalrecos&newnumber=' + newnumberofminimalrecos + '&apikey=' + clientdata.apikey);
        const urlnumberofminimalrecosnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=numberofminimalrecos&newnumber=' + newnumberofminimalrecos + '&apikey=' + clientdata.apikey);
        var urlnumberofminimalrecoswithoutrecos = await urlnumberofminimalrecosnojson.json();
        var urlnumberofminimalrecos = {clientname: urlnumberofminimalrecoswithoutrecos.clientname, city: urlnumberofminimalrecoswithoutrecos.city, apikey: urlnumberofminimalrecoswithoutrecos.apikey, cityname: urlnumberofminimalrecoswithoutrecos.cityname, excludedlocations: urlnumberofminimalrecoswithoutrecos.excludedlocations, websiteChanges: urlnumberofminimalrecoswithoutrecos.websiteChanges, imageChanges: urlnumberofminimalrecoswithoutrecos.imageChanges, topChanges: urlnumberofminimalrecoswithoutrecos.topChanges, inlijstingChanges: urlnumberofminimalrecoswithoutrecos.inlijstingChanges, lengthRecos: urlnumberofminimalrecoswithoutrecos.lengthRecos, categories: urlnumberofminimalrecoswithoutrecos.categories, minimalrecos: newnumberofminimalrecos, status: urlnumberofminimalrecoswithoutrecos.status, cityrecos: clientdata.cityrecos}
        setClientdata(urlnumberofminimalrecos)
    }

    return (
        <div className="login-recommendations-categories-number-of-minimal-recommendations-container">
        <div className="login-recommendations-categories-number-of-minimal-recommendations-header">Minimum number of recommendations</div>
        <div className="login-recommendations-categories-number-of-minimal-recommendations-number">{clientdata.minimalrecos}</div>
        <div className="login-recommendations-categories-number-of-minimal-recommendations-numbers-container">


        {listofnumbers.map((item, index)=>(
        <div className="login-recommendations-categories-number-of-minimal-recommendations-numbers-outer-divs" key={index}>
        <div className="login-recommendations-categories-number-of-minimal-recommendations-number-divs" id={item} onClick={numberofminimalrecosfunction}>
        {item}
        </div>
        </div>  
        ))}
        </div>  
        </div>  
    )
}

export default RecommendationsMinimalNumberOfRecos
