import React, { useContext }from "react";
import { clientdatacontext } from "../LoginValues.jsx";


function RecommendationsNumberOfRecos () {

    const { clientdata, setClientdata } = useContext(clientdatacontext);

    var listofnumbers = [];
    for (let i = 1; i < 101; i++) {
        listofnumbers.push(JSON.stringify(i))
    }


    async function numberofrecosfunction (event){
        var newnumberofrecos = event.target.id;
        // const urlnumberofrecosnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=numberofrecos&newnumber=' + newnumberofrecos + '&apikey=' + clientdata.apikey);
        const urlnumberofrecosnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=numberofrecos&newnumber=' + newnumberofrecos + '&apikey=' + clientdata.apikey);
        var urlnumberofrecoswithoutrecos = await urlnumberofrecosnojson.json();
        var urlnumberofrecos = {clientname: urlnumberofrecoswithoutrecos.clientname, city: urlnumberofrecoswithoutrecos.city, apikey: urlnumberofrecoswithoutrecos.apikey, cityname: urlnumberofrecoswithoutrecos.cityname, excludedlocations: urlnumberofrecoswithoutrecos.excludedlocations, websiteChanges: urlnumberofrecoswithoutrecos.websiteChanges, imageChanges: urlnumberofrecoswithoutrecos.imageChanges, topChanges: urlnumberofrecoswithoutrecos.topChanges, inlijstingChanges: urlnumberofrecoswithoutrecos.inlijstingChanges, lengthRecos: newnumberofrecos, categories: urlnumberofrecoswithoutrecos.categories, minimalrecos: urlnumberofrecoswithoutrecos.minimalrecos, status: urlnumberofrecoswithoutrecos.status, cityrecos: clientdata.cityrecos}
        setClientdata(urlnumberofrecos)
    }

    return (
        <div className="login-recommendations-categories-number-of-recommendations-container">
        <div className="login-recommendations-categories-number-of-recommendations-header">Maximum number of recommendations</div>
        <div className="login-recommendations-categories-number-of-recommendations-number">{clientdata.lengthRecos}</div>
        <div className="login-recommendations-categories-number-of-recommendations-numbers-container">


        {listofnumbers.map((item, index)=>(
        <div className="login-recommendations-categories-number-of-recommendations-numbers-outer-divs" key={index}>
        <div className="login-recommendations-categories-number-of-recommendations-number-divs" id={item} onClick={numberofrecosfunction}>
        {item}
        </div>
        </div>  
        ))}
        </div>  
        </div>  
    )
}

export default RecommendationsNumberOfRecos
