import React, { useContext } from "react";
import DesignSevenBarTop from "./DesignSeven/DesignSevenBarTop";
import DesignSevenPageOne from './DesignSeven/DesignSevenPageOne.jsx';
import DesignSevenPageTwo from './DesignSeven/DesignSevenPageTwo.jsx';
import DesignSevenPageThree from './DesignSeven/DesignSevenPageThree.jsx';
import DesignSevenPageFour from './DesignSeven/DesignSevenPageFour.jsx';
import DesignSevenPageFive from './DesignSeven/DesignSevenPageFive.jsx';
import DesignSevenProgressBar from "./DesignSeven/DesignSevenProgressBar.jsx";
import { designSevenPagecounter } from "./DesignSeven/DesignSevenValues.jsx";


function designSeven() {
    
        // eslint-disable-next-line
        const { designSevenPage, setDesignSevenPage } =  useContext(designSevenPagecounter)

        return (
            <div>
            <DesignSevenBarTop/>
            {designSevenPage === 1 ? <DesignSevenPageOne/> : null}
            {designSevenPage === 2 ? <DesignSevenPageTwo/> : null}
            {designSevenPage === 3 ? <DesignSevenPageThree/> : null}
            {designSevenPage === 4 ? <DesignSevenPageFour/> : null}
            {designSevenPage === 5 ? <DesignSevenPageFive/> : null}
            <DesignSevenProgressBar/>
            </div>
        )
    }

export default designSeven;