import React, { useState, useEffect, useContext } from "react";
import '../CSS/DesignSeven/DesignSevenProgressBar.css';
import { designSevenPagecounter, designSevenMainCategories, designSevenFavoriteInputsArray } from "./DesignSevenValues.jsx";
import { APIdatacall } from "../AppValues.jsx";

function DesignSevenProgressBar() {

      // eslint-disable-next-line
      const { APIdata, setAPIdata } = useContext(APIdatacall);
      const { designSevenPage, setDesignSevenPage } =  useContext(designSevenPagecounter) 
    const { designSevenCategories, setDesignSevenCategories } = useContext(designSevenMainCategories);
    const { designSevenFavoriteInputs, setDesignSevenFavoriteInputs } = useContext(designSevenFavoriteInputsArray);
    const [firstTime, setFirstTime] = useState(true);
    const [firstTimeInput, setFirstTimeInput] = useState(true);

    const [buttonfontsize, setButtonfontsize] = useState("");
    const [buttonmargintop, setButtonmargintop] = useState("");
        

    

    //WIDTH FIX START
    const [widthhappened, setWidthhappened] = useState(false)
    function getWindowDimensions() {
        const { innerWidth: width} = window;
        return {
          width,
        };
      }
      function handleResize() {
        var newwidth = (getWindowDimensions());
        if (newwidth.width >= 1024){
          setButtonfontsize(APIdata.styles.progressbar.progressbarbuttonsfontsizenormal);
          setButtonmargintop(APIdata.styles.progressbar.progressbarbuttonsmargintopnormal);
      } else if (newwidth.width >=720 && newwidth.width < 1024){
          setButtonfontsize(APIdata.styles.progressbar.progressbarbuttonsfontsizetablet);
          setButtonmargintop(APIdata.styles.progressbar.progressbarbuttonsmargintoptablet);
      } else if (newwidth.width < 720){        
          setButtonfontsize(APIdata.styles.progressbar.progressbarbuttonsfontsizemobile);
          setButtonmargintop(APIdata.styles.progressbar.progressbarbuttonsmargintopmobile);
      }}

      const [progresslinecolor, setProgresslinecolor] = useState();   
      function progresslinecolorfunction () {
      if (APIdata.styles.progressbar.progressbarlinecolor === undefined || APIdata.styles.progressbar.progressbarlinecolor === null){
          setProgresslinecolor(APIdata.styles.generalpage.colortwo)
      } else {
          setProgresslinecolor(APIdata.styles.progressbar.progressbarlinecolor)
      }}


      if ( widthhappened === false ) {
        handleResize()
        setWidthhappened(true)
        progresslinecolorfunction()
      }

    useEffect(() => {
        handleResize() 
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      });
    //WIDTH FIX END


    const [progresswidth, setProgresswidth] = useState("20%");   
    const [nextbuttontext, setNextButtonText] = useState(APIdata.styles.generalpage.nextbutton);   
    // eslint-disable-next-line 
    const [backbuttontext, setBackButtonText] = useState(APIdata.styles.generalpage.backbutton);   
    const [backbuttonstate, setBackButtonState] = useState("design-one-backbutton-inactive");   


    useEffect(() => {
        if (designSevenPage === 2){
            setProgresswidth("20%")
            if (backbuttonstate === "design-four-backbutton"){
                setBackButtonState("design-four-backbutton-transition")
                setNextButtonText(APIdata.styles.generalpage.nextbutton)

            }
        } else if (designSevenPage === 3){
            setProgresswidth("100%")
            setBackButtonState("design-four-backbutton")
            setNextButtonText(APIdata.styles.generalpage.againbutton)
            


            }
            

    },[designSevenPage, backbuttonstate])

        function volgende () {
            if (designSevenPage < 6){
                if (designSevenPage !== 2 && designSevenPage !== 3 && designSevenPage !== 5){
            // eslint-disable-next-line
            var nextPage = designSevenPage + 1;
            setDesignSevenPage(nextPage)}

            if (designSevenPage === 2 && designSevenCategories.length !== 0){
                // eslint-disable-next-line
                var nextPage = designSevenPage + 1;
                setDesignSevenPage(nextPage)
            } else if (designSevenPage === 2 && designSevenCategories.length === 0) {
                alert(APIdata.styles.progressbar.categoryerror)
            }

           

                if (designSevenPage === 3){
                    setDesignSevenPage(2)
                    setNextButtonText("Volgende")
                    setDesignSevenCategories([])
                    setDesignSevenFavoriteInputs([])
                } 
        
                }
        }
        function vorige () {
            if (designSevenPage ==3 ){
            var lastPage = designSevenPage - 1;
            setDesignSevenPage(lastPage)}
            if (designSevenPage === 5){
                var lastPage = designSevenPage - 2; //2 omdat we geen scherm 4 meer hebben
                setDesignSevenPage(lastPage)}
      

        }

        if (designSevenPage === 2 && designSevenCategories.length === 1 && firstTime ) {
           volgende(); // Call volgende() immediately when DesignSevenCategories.length > 1
           setFirstTime(false);          
          }

    
      
     
 
       
          
          



    return (
        <div className="design-four-progressbar"
        style={{
            backgroundColor: APIdata.styles.progressbar.progressbarbackgroundcolor
        }}>
        <div className="design-four-progressline"
        style={{
            width: progresswidth,
            backgroundColor: progresslinecolor
        }}>
        </div>
        <div className={backbuttonstate} onClick={vorige}
        style={{
            fontFamily: APIdata.styles.progressbar.progressbarbuttonsfont,
            fontSize: buttonfontsize,
            borderColor: APIdata.styles.progressbar.progressbarbordercolor
        }}>         
        <div className="design-four-backbutton-text"
        style={{
            fontFamily: APIdata.styles.progressbar.progressbarbuttonsfont,
            fontSize: buttonfontsize,
            top: buttonmargintop,
            color: APIdata.styles.progressbar.progressbarcolor
        }}>        
        {backbuttontext}
        </div>  
        </div>
        <div className="design-four-nextbutton" onClick={volgende}
        style={{
            backgroundColor: APIdata.styles.generalpage.colortwo,
            borderColor: APIdata.styles.generalpage.colortwo
        }}> 
        <div className="design-four-nextbutton-text"
        style={{
            fontFamily: APIdata.styles.progressbar.progressbarbuttonsfont,
            fontSize: buttonfontsize,
            top: buttonmargintop
        }}>        
        {nextbuttontext}
        </div>
        </div>
        <a href='../Files/Privacyverklaring Maps Untold.pdf' download target="_blank"
              style = {{ 
                position: "fixed",
                top: "100vh",
                marginTop: -15,
                zIndex: 3000,
                textDecorationLine : 'underline',
                color : 'gray',
                left: 5,
                cursor: 'pointer',                
                fontFamily: "montserrat",
                fontSize: 10,
              }}>
                Privacy Statement
              </a>
        </div>
    )
}

export default DesignSevenProgressBar;