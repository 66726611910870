import React, {useState} from "react";
import LoginRecommendations from "./LoginRecommendations.jsx";
import LoginDashboard from "./LoginDashboard.jsx";
import LoginStyles from "./LoginStyles.jsx";

function LoginScreen () {

    const [stateLogin , setStateLogin] = useState("LoginScreen")


    function changestate (event) {
        setStateLogin(event.target.id)
    }


    return (
        <div>

            <div className="loginscreen-top-buttons-container">
            <div onClick={changestate} id="Dashboard" className="loginscreen-top-buttons">Dashboard</div>
            <div onClick={changestate} id="Styles" className="loginscreen-top-buttons">Styles</div>
            <div onClick={changestate} id="Recommendations" className="loginscreen-top-buttons">Recommendations</div>
        </div>

            {stateLogin === "Dashboard" ? <LoginDashboard/> : null}
            {stateLogin === "Styles" ? <LoginStyles/> : null}
            {stateLogin === "Recommendations" ? <LoginRecommendations/> : null}
            {stateLogin === "LoginScreen" ? <LoginScreenAnimation/> : null}



            
        </div>
    )

}

function LoginScreenAnimation () {
    return (
<div className="loginscreen-animation">
<img src={require("../images/maps-untold-logo.png")} alt="logo" className="loginscreen-animation-inner-div-image"/>
</div>
    )}

export default LoginScreen