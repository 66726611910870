import React, { useContext } from "react";
import DesignOneBarTop from "./DesignOne/DesignOneBarTop";
import DesignOnePageOne from './DesignOne/DesignOnePageOne.jsx';
import DesignOnePageTwo from './DesignOne/DesignOnePageTwo.jsx';
import DesignOnePageThree from './DesignOne/DesignOnePageThree.jsx';
import DesignOnePageFour from './DesignOne/DesignOnePageFour.jsx';
import DesignOneProgressBar from "./DesignOne/DesignOneProgressBar.jsx";
import { designOnePagecounter } from "./DesignOne/DesignOneValues.jsx";

function DesignOne() {

    // eslint-disable-next-line
    const { designOnePage, setDesignOnePage } =  useContext(designOnePagecounter)

    return (
        <div>
        <DesignOneBarTop/>
        {designOnePage === 1 ? <DesignOnePageOne/> : null}
        {designOnePage === 2 ? <DesignOnePageTwo/> : null}
        {designOnePage === 3 ? <DesignOnePageThree/> : null}
        {designOnePage === 4 ? <DesignOnePageFour/> : null}
        <DesignOneProgressBar/>
        </div>
    )
}

export default DesignOne;