import React, { useState, useEffect, useContext } from "react";
import '../CSS/DesignOne/DesignOneProgressBar.css';
import { designOnePagecounter, designOneMainCategories, designOneFavoriteInputsArray } from "./DesignOneValues.jsx";
import { APIdatacall } from "../AppValues.jsx";


function DesignOneProgressBar() {

      // eslint-disable-next-line
      const { APIdata, setAPIdata } = useContext(APIdatacall);
      const { designOnePage, setDesignOnePage } =  useContext(designOnePagecounter)

    const { designOneCategories, setDesignOneCategories } = useContext(designOneMainCategories);
    const { designOneFavoriteInputs, setDesignOneFavoriteInputs } = useContext(designOneFavoriteInputsArray);

    const [buttonfontsize, setButtonfontsize] = useState("");
    const [buttonmargintop, setButtonmargintop] = useState("");



    //WIDTH FIX START
    const [widthhappened, setWidthhappened] = useState(false)
    function getWindowDimensions() {
        const { innerWidth: width} = window;
        return {
          width,
        };
      }
      function handleResize() {
        var newwidth = (getWindowDimensions());
        if (newwidth.width >= 1024){
          setButtonfontsize(APIdata.styles.progressbar.progressbarbuttonsfontsizenormal);
          setButtonmargintop(APIdata.styles.progressbar.progressbarbuttonsmargintopnormal);
      } else if (newwidth.width >=720 && newwidth.width < 1024){
          setButtonfontsize(APIdata.styles.progressbar.progressbarbuttonsfontsizetablet);
          setButtonmargintop(APIdata.styles.progressbar.progressbarbuttonsmargintoptablet);
      } else if (newwidth.width < 720){        
          setButtonfontsize(APIdata.styles.progressbar.progressbarbuttonsfontsizemobile);
          setButtonmargintop(APIdata.styles.progressbar.progressbarbuttonsmargintopmobile);
      }}

      const [progresslinecolor, setProgresslinecolor] = useState();   
      function progresslinecolorfunction () {
      if (APIdata.styles.progressbar.progressbarlinecolor === undefined || APIdata.styles.progressbar.progressbarlinecolor === null){
          setProgresslinecolor(APIdata.styles.generalpage.colortwo)
      } else {
          setProgresslinecolor(APIdata.styles.progressbar.progressbarlinecolor)
      }}


      if ( widthhappened === false ) {
        handleResize()
        setWidthhappened(true)
        progresslinecolorfunction()
      }

    useEffect(() => {
        handleResize() 
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      });
    //WIDTH FIX END


    const [progresswidth, setProgresswidth] = useState("25%");   
    const [nextbuttontext, setNextButtonText] = useState("Volgende");   
    // eslint-disable-next-line 
    const [backbuttontext, setBackButtonText] = useState("Terug");   
    const [backbuttonstate, setBackButtonState] = useState("design-one-backbutton-inactive");   

    useEffect(() => {
        if (designOnePage === 1){
            setProgresswidth("25%")
            if (backbuttonstate === "design-one-backbutton"){
                setBackButtonState("design-one-backbutton-transition")
            }
        } else if (designOnePage === 2){
            setProgresswidth("50%")
            setBackButtonState("design-one-backbutton")
        } else if (designOnePage === 3){
            setProgresswidth("75%")
            setNextButtonText("Volgende")
        } else if (designOnePage === 4){
            setProgresswidth("100%")
            setNextButtonText("Opnieuw")
        }
    },[designOnePage, backbuttonstate])

        function volgende () {
            if (designOnePage < 5){
                if (designOnePage !== 2 && designOnePage !== 3 && designOnePage !== 4){
            // eslint-disable-next-line
            var nextPage = designOnePage + 1;
            setDesignOnePage(nextPage)}

            if (designOnePage === 2 && designOneCategories.length !== 0){
                // eslint-disable-next-line
                var nextPage = designOnePage + 1;
                setDesignOnePage(nextPage)
            } else if (designOnePage === 2 && designOneCategories.length === 0) {
                alert("voer minimaal 1 category in")
            }

                if (designOnePage === 3 && designOneFavoriteInputs.length !== 0){
                    // eslint-disable-next-line
                    var nextPage = designOnePage + 1;
                    setDesignOnePage(nextPage)
                } else if (designOnePage === 3 && designOneFavoriteInputs.length === 0) {
                    alert("voer minimaal 1 favoriete plek in")
                }

                if (designOnePage === 4){
                    setDesignOnePage(2)
                    setNextButtonText("Volgende")
                    setDesignOneCategories([])
                    setDesignOneFavoriteInputs([])
                } 
                }
        }
        function vorige () {
            if (designOnePage > 1){
            var lastPage = designOnePage - 1;
            setDesignOnePage(lastPage)}
        }

    return (
        <div className="design-one-progressbar"
        style={{
            backgroundColor: APIdata.styles.progressbar.progressbarbackgroundcolor
        }}>
        <div className="design-one-progressline"
        style={{
            width: progresswidth,
            backgroundColor: progresslinecolor
        }}>
        </div>
        <div className={backbuttonstate} onClick={vorige}
        style={{
            fontFamily: APIdata.styles.progressbar.progressbarbuttonsfont,
            fontSize: buttonfontsize,
            borderColor: APIdata.styles.progressbar.progressbarbordercolor
        }}>         
        <div className="design-one-backbutton-text"
        style={{
            fontFamily: APIdata.styles.progressbar.progressbarbuttonsfont,
            fontSize: buttonfontsize,
            top: buttonmargintop,
            color: APIdata.styles.progressbar.progressbarcolor
        }}>        
        {backbuttontext}
        </div>  
        </div>
        <div className="design-one-nextbutton" onClick={volgende}
        style={{
            backgroundColor: APIdata.styles.generalpage.colortwo,
            borderColor: APIdata.styles.generalpage.colortwo
        }}> 
        <div className="design-one-nextbutton-text"
        style={{
            fontFamily: APIdata.styles.progressbar.progressbarbuttonsfont,
            fontSize: buttonfontsize,
            top: buttonmargintop
        }}>        
        {nextbuttontext}
        </div>
        </div>
        <a href='../Files/Privacyverklaring Maps Untold.pdf' download target="_blank"
              style = {{ 
                position: "fixed",
                top: "100vh",
                marginTop: -15,
                zIndex: 3000,
                textDecorationLine : 'underline',
                color : 'gray',
                left: 5,
                cursor: 'pointer',                
                fontFamily: "montserrat",
                fontSize: 10,
              }}>
                Privacy Statement
              </a>
        </div>
    )
}

export default DesignOneProgressBar;