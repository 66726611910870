import React, {useState, useEffect, useContext} from "react";
import '../CSS/DesignFour/DesignFourPageThree.css';
import {designFourMainCategories, designFourFavoriteInputsArray} from "./DesignFourValues.jsx";
import {APIdatacall} from "../AppValues.jsx";


function DesignFourPageThree() {
    const {designFourFavoriteInputs, setDesignFourFavoriteInputs} = useContext(designFourFavoriteInputsArray);
    // eslint-disable-next-line
    const {APIdata, setAPIdata} = useContext(APIdatacall);
    // eslint-disable-next-line
    const {designFourCategories, setDesignFourCategories} = useContext(designFourMainCategories);

    const [updatefavoinputshappened, setUpdatefavoinputshappened] = useState(false);


    function updatefavoinputs() {
        var newdesignFourFavoriteInputslist = [];
        for (let i = 0; i < designFourFavoriteInputs.length; i++) {
            if (designFourCategories.includes(JSON.parse(designFourFavoriteInputs[i].favoriteindexnumber))) {
                newdesignFourFavoriteInputslist.push(designFourFavoriteInputs[i])
            }
        }
        setDesignFourFavoriteInputs(newdesignFourFavoriteInputslist);
    }

    if (updatefavoinputshappened === false) {
        updatefavoinputs();
        setUpdatefavoinputshappened(true);
    }


    const [animationhappened, setAnimationhappened] = useState(false);
    const [animationthirdpage, setAnimationthirdpage] = useState({
        left: {
            animation: 'x 0.3s',
            animationName: 'fadeInContent'
        }, right: {animation: 'x 0.4s', animationName: 'fadeInContent'}
    });

    function animationchange() {
        if (animationhappened === false) {
            setAnimationhappened(true)
            setAnimationthirdpage({left: {animation: '', animationName: ''}, right: {animation: '', animationName: ''}})
        }
    }

    var cityexplorertext;
    if (APIdata.styles.generalpage.cityexplorertext !== null) {
        cityexplorertext = APIdata.styles.generalpage.cityexplorertext;
    } else {
        cityexplorertext = "Stadsontdekker " + APIdata.cityname;
    }

    var threepageheaderleft;
    if (APIdata.styles.pagethree.headerleft !== null) {
        threepageheaderleft = APIdata.styles.pagethree.headerleft;
    } else {
        threepageheaderleft = "Voer je favoriete plekken uit je woonplaats in";
    }

    var threepagesubheaderleft;
    if (APIdata.styles.pagethree.subheaderleft !== null) {
        threepagesubheaderleft = APIdata.styles.pagethree.subheaderleft;
    } else {
        threepagesubheaderleft = "Je favoriete plekken om te bezoeken helpen ons jou te inspireren " + APIdata.cityname + " te ontdekken. Jouw favoriete plekken kunnen van alles zijn, van een restaurant uit je woonplaats tot een museum van een laatste stedentrip of een wandelpark van op vakantie. Hoe meer we van jou leren kennen, hoe beter de Stadsontdekker werkt!";
    }

    const [fontheightheader, setFontheightheader] = useState("");
    const [fontheightsubheader, setFontheightsubheader] = useState("");
    const [lineheightsubheader, setLineheightsubheader] = useState("");
    const [stadsontdekkersize, setStadsontdekkersize] = useState("");
    const [stadsontdekkermapsuntoldfontsize, setStadsontdekkermapsuntoldfontsize] = useState("");
    const [stadsontdekkermapsuntoldmargintop, setStadsontdekkermapsuntoldmargintop] = useState("");
    const [stadsontdekkertextmargintop, setStadsontdekkertextmargintop] = useState("");
    const [categoryheaderrightfontsize, setCategoryheaderrightfontsize] = useState("");
    const [categorysubheaderrightfontsize, setCategorysubheaderrightfontsize] = useState("");
    const [inputfontsize, setInputfontsize] = useState("");
    const [autocompletefontsize, setAutocompletefontsize] = useState("");
    const [favoritefontsize, setFavoritefontsize] = useState("");

//WIDTH FIX START
    const [widthhappened, setWidthhappened] = useState(false)

    function getWindowDimensions() {
        const {innerWidth: width} = window;
        return {
            width,
        };
    }

    function handleResize() {
        var newwidth = (getWindowDimensions());
        if (newwidth.width >= 1024) {
            setFontheightheader(APIdata.styles.pagethree.headerleftfontsizenormal);
            setFontheightsubheader(APIdata.styles.pagethree.subheaderleftfontsizenormal);
            setLineheightsubheader(APIdata.styles.pagethree.subheaderleftlineheightnormal);
            setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizenormal);
            setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizenormal);
            setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintopnormal);
            setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintopnormal);
            setCategoryheaderrightfontsize(APIdata.styles.pagethree.categoryheaderrightfontsizenormal);
            setCategorysubheaderrightfontsize(APIdata.styles.pagethree.categorysubheaderrightfontsizenormal);
            setInputfontsize(APIdata.styles.pagethree.inputfontsizenormal);
            setAutocompletefontsize(APIdata.styles.pagethree.autocompletefontsizenormal);
            setFavoritefontsize(APIdata.styles.pagethree.favoritefontsizenormal);
        } else if (newwidth.width >= 720 && newwidth.width < 1024) {
            setFontheightheader(APIdata.styles.pagethree.headerleftfontsizetablet);
            setFontheightsubheader(APIdata.styles.pagethree.subheaderleftfontsizetablet);
            setLineheightsubheader(APIdata.styles.pagethree.subheaderleftlineheighttablet);
            setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizetablet);
            setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizetablet);
            setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintoptablet);
            setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintoptablet);
            setCategoryheaderrightfontsize(APIdata.styles.pagethree.categoryheaderrightfontsizetablet);
            setCategorysubheaderrightfontsize(APIdata.styles.pagethree.categorysubheaderrightfontsizetablet);
            setInputfontsize(APIdata.styles.pagethree.inputfontsizetablet);
            setAutocompletefontsize(APIdata.styles.pagethree.autocompletefontsizetablet);
            setFavoritefontsize(APIdata.styles.pagethree.favoritefontsizetablet);
        } else if (newwidth.width < 720) {
            setFontheightheader(APIdata.styles.pagethree.headerleftfontsizemobile);
            setFontheightsubheader(APIdata.styles.pagethree.subheaderleftfontsizemobile);
            setLineheightsubheader(APIdata.styles.pagethree.subheaderleftlineheightmobile);
            setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizemobile);
            setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizemobile);
            setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintopmobile);
            setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintopmobile);
            setCategoryheaderrightfontsize(APIdata.styles.pagethree.categoryheaderrightfontsizemobile);
            setCategorysubheaderrightfontsize(APIdata.styles.pagethree.categorysubheaderrightfontsizemobile);
            setInputfontsize(APIdata.styles.pagethree.inputfontsizemobile);
            setAutocompletefontsize(APIdata.styles.pagethree.autocompletefontsizemobile);
            setFavoritefontsize(APIdata.styles.pagethree.favoritefontsizemobile);
        }
    }

    if (widthhappened === false) {
        handleResize()
        setWidthhappened(true)
    }
    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
//WIDTH FIX END


    //RIGHT SIDE

    var threepageautocompletetrigger;
    if (APIdata.styles.pagethree.autocompletetrigger !== null) {
        threepageautocompletetrigger = APIdata.styles.pagethree.autocompletetrigger;
    } else {
        threepageautocompletetrigger = 4;
    }

    var autocompletenumberofsuggestions;
    if (APIdata.styles.pagethree.autocompletenumberofsuggestions !== null) {
        autocompletenumberofsuggestions = APIdata.styles.pagethree.autocompletenumberofsuggestions;
    } else {
        autocompletenumberofsuggestions = 5;
    }

    function onClickFavorite(event) {
        var favoritelist = [];
        var favoriteobject;
        var favoriteid = event.target.id;
        var favoritetitle = document.getElementById(favoriteid).getAttribute("data-title");
        var favoriteindexnumber = document.getElementById(favoriteid).getAttribute("data-indexnumber");

        for (let i = 0; i < designFourFavoriteInputs.length; i++) {
            favoritelist.push(designFourFavoriteInputs[i]);
        }

        favoriteobject = {
            favoriteid: favoriteid,
            favoritetitle: favoritetitle,
            favoriteindexnumber: favoriteindexnumber,
            favoritehtml: <div className="design-four-page-three-right-favorite-text" style={{
                fontFamily: APIdata.styles.pagethree.favoritefont,
                fontSize: favoritefontsize
            }}>{favoritetitle}</div>
        };

        favoritelist.push(favoriteobject);

        favoritelist = favoritelist.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.favoriteid === value.favoriteid && t.favoriteindexnumber === value.favoriteindexnumber
            )))
        setDesignFourFavoriteInputs(favoritelist)
    }


    function onClickKruis(event) {
        var favoriteidkruis = event.target.id;
        var favoriteidwithoutkruis = favoriteidkruis.substring(0, favoriteidkruis.length - 5);
        var filtered = designFourFavoriteInputs.filter(function (obj) {
            return obj.favoriteid !== favoriteidwithoutkruis;
        });
        setDesignFourFavoriteInputs(filtered)
    }

    function Inputdisplay() {

        designFourCategories.sort(function (a, b) {
            return a - b
        });

        var startquery = []
        for (let i = 0; i < designFourCategories.length; i++) {
            startquery.push({indexnumber: designFourCategories[i], indexvalue: ""})
        }

        var startautocomplete = []

        for (let i = 0; i < APIdata.categories.length; i++) {
            startautocomplete.push({indexnumber: i, indexvalue: ""})
        }

        const [query, setQuery] = useState(startquery);
        const [autocompletetrigger, setAutocompletetrigger] = useState(false);
        const [autocomplete, setAutocomplete] = useState(startautocomplete);
        const [finalautocomplete, setFinalautocomplete] = useState(startautocomplete);
        const [previousinputbarfocus, setPreviousinputbarfocus] = useState();

        function handleInputFocus(index) {
            var inputbarfocus = index.target.name;
            if (previousinputbarfocus !== inputbarfocus) {
                setPreviousinputbarfocus(inputbarfocus);
                setAutocomplete(startautocomplete);
                setFinalautocomplete(startautocomplete);
                setQuery(startquery);
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
                );
            }
        }

        function updateautocomplete(list) {
            var newlist = [];

            for (let i = 0; i < list.length; i++) {
                var newlistindexvaluelist = [];
                var newlistindexnumber = list[i].indexnumber
                var newlistindexvalue = list[newlistindexnumber].indexvalue;
                var forlooplength;

                if (newlistindexvalue.length > autocompletenumberofsuggestions) {
                    forlooplength = autocompletenumberofsuggestions;
                } else {
                    forlooplength = newlistindexvalue.length;
                }

                for (let j = 0; j < forlooplength; j++) {
                    newlistindexvaluelist.push(<div
                        key={j}
                        className="autocompletesuggestions"
                        onClick={onClickFavorite}
                        id={newlistindexvalue[j]._id + newlistindexnumber}
                        data-title={newlistindexvalue[j].title}
                        data-indexnumber={newlistindexnumber}
                        style={{
                            fontFamily: APIdata.styles.pagethree.autocompletefont,
                            fontSize: autocompletefontsize
                        }}>{newlistindexvalue[j].title + " (" + newlistindexvalue[j].category + " in " + newlistindexvalue[j].city + ")"}</div>)
                }

                newlist.push({indexnumber: newlistindexnumber, indexvalue: newlistindexvaluelist})
            }
            setFinalautocomplete(newlist);
        }

        function inputHandler(event) {
            setAutocompletetrigger(true);
            var indexnumber = event.target.name;
            var indexvalue = event.target.value;
            for (let i = 0; i < query.length; i++) {
                // eslint-disable-next-line
                if (query[i].indexnumber == indexnumber) {
                    var indexlijst = [];

                    for (let j = 0; j < query.length; j++) {
                        // eslint-disable-next-line
                        if (query[j].indexnumber == indexnumber) {
                            indexlijst.push({indexnumber: indexnumber, indexvalue: indexvalue})
                        } else {
                            indexlijst.push({indexnumber: query[j].indexnumber, indexvalue: query[j].indexvalue})
                        }
                        setQuery(indexlijst);
                    }
                } else {
                }
            }
        }

        async function getAutocomplete() {
            for (let i = 0; i < designFourCategories.length; i++) {
                var numbertobeinserted = designFourCategories[i];

                if (query[i].indexvalue.length !== undefined) {
                    if (query[i].indexvalue.length < threepageautocompletetrigger) {
                        if (autocompletetrigger === true) {
                            setAutocompletetrigger(false);
                        }
                    } else if (query[i].indexvalue.length >= threepageautocompletetrigger) {
                        if (autocompletetrigger === true) {

                            setAutocompletetrigger(false);
                            const apicallautocomplete = query[i].indexvalue.replaceAll(" ", "%20");
                            // const urlautocompletenojson = await fetch('https://api-mapsuntold.nl/autocomplete?term=' + apicallautocomplete);
                            //const urlautocompletenojson = await fetch('https://api-mapsuntold.nl/autocomplete?term=' + apicallautocomplete + "&searchscope=" + APIdata.searchscope);
                            const urlautocompletenojson = await fetch('https://api-mapsuntold.nl/autocomplete?term=' + apicallautocomplete + "&searchscope=" + APIdata.searchscope);
                            var urlautocomplete = await urlautocompletenojson.json();
                            var listautocomplete = [];
                            for (let j = 0; j < autocomplete.length; j++) {
                                // eslint-disable-next-line
                                if (autocomplete[j].indexnumber == numbertobeinserted) {
                                    listautocomplete.push({
                                        indexnumber: numbertobeinserted,
                                        indexvalue: urlautocomplete
                                    });
                                } else {
                                    listautocomplete.push({
                                        indexnumber: autocomplete[j].indexnumber,
                                        indexvalue: autocomplete[j].indexvalue
                                    });
                                }
                            }

                            updateautocomplete(listautocomplete);

                        }
                    }
                }
            }
        }

        getAutocomplete();

        function handleClickForFocus(event) {
            var id = event.target.id
            setTimeout(() => {
                window.document.getElementById(id).focus();
            }, 0);
        }


        return (
            <div className="design-four-page-three-right-categories-container"
                 style={{
                     animation: animationthirdpage.right.animation,
                     animationName: animationthirdpage.right.animationName,
                 }}
            >

                {APIdata.categories.map((category, index) => (
                    <div className="design-four-page-three-right-category-container"
                         key={index}
                         id={index}
                         style={{}}
                         value={index}
                    >
                        {designFourCategories.includes(index) ?
                            <div className="design-four-page-three-right-category-part">
                                <div className="design-four-page-three-right-category-part-header"
                                     style={{
                                         fontFamily: APIdata.styles.pagethree.rightheaderfont,
                                         fontSize: categoryheaderrightfontsize,
                                     }}>
                                    {category.parentcategory}
                                </div>
                                <div className="design-one-page-three-right-category-part-subheader"
                                     style={{
                                         fontFamily: APIdata.styles.pagethree.rightsubheaderfont,
                                         fontSize: categorysubheaderrightfontsize,
                                     }}>
                                    {category.texts.categorydescription}
                                </div>
                                <div>
                                    <input
                                        value={query.index}
                                        onChange={inputHandler}
                                        placeholder={category.texts.categoryplaceholder}
                                        name={index}
                                        className="design-four-page-three-right-category-part-inputbar"
                                        onFocus={handleInputFocus}
                                        id={"id" + index}
                                        onClick={handleClickForFocus}
                                        style={{
                                            fontFamily: APIdata.styles.pagethree.inputfont,
                                            fontSize: inputfontsize,
                                        }}
                                        autoComplete="off" autoCorrect="off"
                                    />
                                    <div className="design-four-page-three-right-category-part-suggestions-container">
                                        {finalautocomplete.map((autocompleteitem, indexx) => (
                                            <div
                                                key={indexx}
                                                id={indexx}
                                                style={{}}
                                                value={indexx}
                                            >

                                                {designFourCategories.includes(autocompleteitem.indexnumber) && index === autocompleteitem.indexnumber ?
                                                    <div
                                                        className="design-four-page-three-right-category-part-suggestion">
                                                        {autocompleteitem.indexvalue}
                                                    </div>
                                                    :
                                                    null}
                                            </div>
                                        ))}
                                    </div>

                                    <div>
                                        {designFourFavoriteInputs.map((favorite, indexx) => (
                                            <div className="design-four-page-three-right-favorites-container"
                                                 key={indexx}
                                                 id={indexx}
                                                 style={{}}
                                                 value={indexx}
                                            >
                                                {designFourCategories.includes(parseInt(favorite.favoriteindexnumber)) && index === parseInt(favorite.favoriteindexnumber) ?
                                                    <div className="design-four-page-three-right-favorite-part"
                                                         style={{backgroundColor: APIdata.styles.generalpage.colortwo}}>
                                                        {favorite.favoritehtml}
                                                        <div className="design-four-page-three-right-favorite-kruis">
                                                            <img src="DesignThree/CategoryImages/Cross Icon White.png"
                                                                 alt="img"
                                                                 className="design-four-page-three-right-favorite-kruis-image"
                                                                 id={favorite.favoriteid + "kruis"}
                                                                 onClick={onClickKruis}
                                                                 kruis-title={favorite.favoritetitle}
                                                                 kruis-indexnumber={favorite.favoriteindexnumber}/>
                                                        </div>
                                                    </div>
                                                    :
                                                    null}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            :
                            null}
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className="design-four-page-three-body" onClick={animationchange}>
            <div className="design-four-page-three-left-body"
                 style={{
                     backgroundColor: APIdata.styles.generalpage.leftcolor,
                 }}>

                <div className="design-four-page-three-left-cityexplorer"
                     style={{
                         marginTop: APIdata.styles.generalpage.bartopheight,
                         paddingTop: APIdata.styles.generalpage.citydiscoveryheight
                     }}>
                    <div className="design-four-page-three-left-cityexplorer-logo">
                        <img src={require("../images/maps-untold-logo-small.png")} alt="logo"
                             className="design-four-page-three-left-cityexplorer-logo-image"/>
                    </div>
                    <div className="design-four-page-three-left-cityexplorer-mapsuntold">
                        <div className="design-four-page-three-left-cityexplorer-mapsuntold-text"
                             style={{
                                 fontFamily: APIdata.styles.generalpage.stadsontdekkermapsuntoldfont,
                                 fontSize: stadsontdekkermapsuntoldfontsize,
                                 marginTop: stadsontdekkermapsuntoldmargintop
                             }}>
                            Maps Untold
                        </div>
                    </div>
                    <div className="design-four-page-three-left-cityexplorer-line">
                    </div>
                    <div className="design-four-page-three-left-cityexplorer-city">
                        <div className="design-four-page-three-left-cityexplorer-city-text"
                             style={{
                                 fontFamily: APIdata.styles.generalpage.stadsontdekkertextfont,
                                 color: APIdata.styles.generalpage.colorone,
                                 fontSize: stadsontdekkersize,
                                 marginTop: stadsontdekkertextmargintop
                             }}>
                            {cityexplorertext}
                        </div>
                    </div>
                </div>

                <div className="design-four-page-three-left-header"
                     style={{
                         fontFamily: APIdata.styles.generalpage.headerleftfont,
                         fontSize: fontheightheader,
                         color: APIdata.styles.generalpage.headerleftcolor,
                         animation: animationthirdpage.left.animation,
                         animationName: animationthirdpage.left.animationName,
                     }}>
                    {threepageheaderleft}
                </div>
                <div className="design-four-page-three-left-subheader"
                     style={{
                         fontFamily: APIdata.styles.generalpage.subheaderleftfont,
                         fontSize: fontheightsubheader,
                         color: APIdata.styles.generalpage.subheaderleftcolor,
                         lineHeight: lineheightsubheader,
                         animation: animationthirdpage.left.animation,
                         animationName: animationthirdpage.left.animationName,
                     }}>
                    {threepagesubheaderleft}

                </div>
            </div>
            <div className="design-four-page-three-right-body"
                 style={{
                     backgroundColor: APIdata.styles.generalpage.rightcolor,
                     paddingBottom: 100,
                 }}>
                <div className="design-four-page-three-right-first-container" style={{
                    marginTop: APIdata.styles.pagethree.rightmargintop,
                }}>
                </div>

                <Inputdisplay/>

            </div>
        </div>
    )
}

export default DesignFourPageThree;
