import React from "react"


function NotFound (){
    return (
<div style={{width: "100%", height: "100vh", backgroundColor: "#f7f7f7", textAlign: "center"}}> 
<div style={{fontFamily: "montserrat", fontSize: 30, fontWeight: 500, paddingTop: "30vh"}}>
    Page Not Found...  
</div>
<div style={{fontFamily: "montserrat", fontSize: 15, fontWeight: 400, marginTop: "5vh"}}>
    Maps Untold 
</div>
</div>
    )
}

export default NotFound;