import React, { useContext } from "react";
import '../CSS/DesignOne/DesignOneBarTop.css';

import { APIdatacall } from "../AppValues.jsx";

function DesignOneBarTop() {

    // eslint-disable-next-line
    const { APIdata, setAPIdata } = useContext(APIdatacall);

    return (
        <div className="design-one-bartop"
        style={{
            height: APIdata.styles.generalpage.bartopheight,
            backgroundColor: APIdata.styles.bartop.bartopbackgroundcolor
        }}>
        </div>
    )
}

export default DesignOneBarTop;