import React, { useContext }from "react";
import { clientdatacontext } from "../LoginValues.jsx";


function RecommendationsTopLocations () {

// eslint-disable-next-line
    const { clientdata, setClientdata } = useContext(clientdatacontext);


    var listoflocationsintopchangeslist = [];
    for (let i = 0; i < clientdata.topChanges.length; i++) {
        listoflocationsintopchangeslist.push(clientdata.topChanges[i].locationid)
    }

    async function topchangesfunction (event){
        var titleaddresssplit = event.target.id.split(" ");
        var action = titleaddresssplit[titleaddresssplit.length - 1];

        if (action === "add"){
            // eslint-disable-next-line
            var locationid = event.target.id.slice(0,-4)
            var topnumber = document.getElementById(event.target.id.slice(0,-4) + " input").value.toString()
            document.getElementById(event.target.id.slice(0,-4) + " input").value = ''
            // eslint-disable-next-line
            // const urltopChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=topChanges&titleaddress=' + titleaddress + '&action=add&top=' + topnumber + '&apikey=' + clientdata.apikey);
            // const urltopChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=topChanges&titleaddress=' + titleaddress + '&action=add&top=' + topnumber + '&apikey=' + clientdata.apikey);
            // const urltopChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=topChanges&locationid=' + locationid + '&action=add&top=' + topnumber + '&apikey=' + clientdata.apikey);
            const urltopChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=topChanges&locationid=' + locationid + '&action=add&top=' + topnumber + '&apikey=' + clientdata.apikey);
            // eslint-disable-next-line 
            var urltopChangeswithoutrecos = await urltopChangesnojson.json();
            // eslint-disable-next-line
             var urltopChanges = {clientname: urltopChangeswithoutrecos.clientname, city: urltopChangeswithoutrecos.city, apikey: urltopChangeswithoutrecos.apikey, cityname: urltopChangeswithoutrecos.cityname, excludedlocations: urltopChangeswithoutrecos.excludedlocations, websiteChanges: urltopChangeswithoutrecos.websiteChanges, imageChanges: urltopChangeswithoutrecos.imageChanges, topChanges: urltopChangeswithoutrecos.topChanges, inlijstingChanges: urltopChangeswithoutrecos.inlijstingChanges, lengthRecos: urltopChangeswithoutrecos.lengthRecos, categories: urltopChangeswithoutrecos.categories, minimalrecos: urltopChangeswithoutrecos.minimalrecos, status: urltopChangeswithoutrecos.status, cityrecos: clientdata.cityrecos}
            setClientdata(urltopChanges)
        } else if (action === "delete"){
            // eslint-disable-next-line
            var locationid = event.target.id.slice(0,-7)
            document.getElementById(event.target.id.slice(0,-7) + " input")
            // eslint-disable-next-line
            // const urltopChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=topChanges&titleaddress=' + titleaddress + '&action=delete&apikey=' + clientdata.apikey);
            // const urltopChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=topChanges&titleaddress=' + titleaddress + '&action=delete&apikey=' + clientdata.apikey);
            // const urltopChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=topChanges&locationid=' + locationid + '&action=delete&apikey=' + clientdata.apikey);
            const urltopChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=topChanges&locationid=' + locationid + '&action=delete&apikey=' + clientdata.apikey);
            // eslint-disable-next-line
            var urltopChangeswithoutrecos = await urltopChangesnojson.json();
            // eslint-disable-next-line
             var urltopChanges = {clientname: urltopChangeswithoutrecos.clientname, city: urltopChangeswithoutrecos.city, apikey: urltopChangeswithoutrecos.apikey, cityname: urltopChangeswithoutrecos.cityname, excludedlocations: urltopChangeswithoutrecos.excludedlocations, websiteChanges: urltopChangeswithoutrecos.websiteChanges, imageChanges: urltopChangeswithoutrecos.imageChanges, topChanges: urltopChangeswithoutrecos.topChanges, inlijstingChanges: urltopChangeswithoutrecos.inlijstingChanges, lengthRecos: urltopChangeswithoutrecos.lengthRecos, categories: urltopChangeswithoutrecos.categories, minimalrecos: urltopChangeswithoutrecos.minimalrecos, status: urltopChangeswithoutrecos.status, cityrecos: clientdata.cityrecos}
            setClientdata(urltopChanges)
        }}





    return (    

        <div className="login-recommendations-categories-top-changes-locations-container">
    
            <div className="login-recommendations-categories-locations-top-changes-locations-list-included-header">Items in top list</div>
    
            {clientdata.cityrecos.map((item, index)=>(
    
            <div style={{ display: "inline-block"}} key={index}>
            {listoflocationsintopchangeslist.includes(item._id) ? 
            <div className="login-recommendations-categories-location-container">
            <img src= {item.imageURL} alt="img" style={{width: 100, height:100}}/>
            <div className="login-recommendations-categories-location-text-top">{item.title.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text-top">{item.city.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text-top">{item.address.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text-top">{item.website.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text-top">{item.category.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text-top"> top: {clientdata.topChanges[clientdata.topChanges.findIndex(x => x.locationid === item._id)].top.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-button" id={item._id + " delete"} onClick={topchangesfunction}> delete from list </div> 
            </div> 
            : null }
            </div>
            ))}
    
    
    
            <div className="login-recommendations-categories-locations-top-changes-locations-list-excluded-header">Items not in top list</div>
    
            {clientdata.cityrecos.map((item, index)=>(
            <div style={{ display: "inline-block"}} key={index}>
            {listoflocationsintopchangeslist.includes(item._id) ? 
            null :
            <div className="login-recommendations-categories-location-container">
            <img src= {item.imageURL} alt="img" style={{width: 100, height:100}}/>
            <div className="login-recommendations-categories-location-text">{item.title.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.city.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.address.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.website.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.category.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-button-top" id={item._id + " add"} onClick={topchangesfunction}> add to list </div> 
            <input className="login-recommendations-categories-location-input-top" id={item._id + " input"} type = "number"></input>
            </div> }
            </div>
            ))}
            </div>
)
}

export default RecommendationsTopLocations
