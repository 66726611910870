import React, { useContext, useState }from "react";
import { clientdatacontext } from "./LoginValues.jsx";

import RecommendationsAllLocations from "./Recommendationsfolder/RecommendationsAllLocations.jsx";
import RecommendationsNumberOfRecos from "./Recommendationsfolder/RecommendationsNumberOfRecos.jsx";
import RecommendationsMinimalNumberOfRecos from "./Recommendationsfolder/RecommendationsMinimalNumberOfRecos.jsx";
import RecommendationsCategories from "./Recommendationsfolder/RecommendationsCategories.jsx";
import RecommendationsExcludedLocations from "./Recommendationsfolder/RecommendationsExcludedLocations.jsx";
import RecommendationsWebsites from "./Recommendationsfolder/RecommendationsWebsites.jsx";
import RecommendationsImages from "./Recommendationsfolder/RecommendationsImages.jsx";
import RecommendationsTopLocations from "./Recommendationsfolder/RecommendationsTopLocations.jsx";
import RecommendationsFrames from "./Recommendationsfolder/RecommendationsFrames.jsx";


function LoginRecommendations () {

// eslint-disable-next-line
    const { clientdata, setClientdata } = useContext(clientdatacontext);
    const [stateRecommendations , setStateRecommendations] = useState("")


    function handleclickrecosstatus (event) {
        setStateRecommendations(event.target.id)
    }


    return (
        <div className="login-recommendations-container">

            <div className="login-recommendations-categories-container">
            <div className="login-recommendations-categories" onClick={handleclickrecosstatus} id="AllLocations"> All locations</div>
            <div className="login-recommendations-categories" onClick={handleclickrecosstatus} id="NumberOfRecos"> Number of Recos</div>
            <div className="login-recommendations-categories" onClick={handleclickrecosstatus} id="MinimalNumberOfRecos"> Minimum number of Recos</div>
            <div className="login-recommendations-categories" onClick={handleclickrecosstatus} id="Categories"> Categories</div>
            <div className="login-recommendations-categories" onClick={handleclickrecosstatus} id="ExcludedLocations"> Excluded locations</div>
            <div className="login-recommendations-categories" onClick={handleclickrecosstatus} id="Websites"> Websites</div>
            <div className="login-recommendations-categories" onClick={handleclickrecosstatus} id="Images"> Images</div>
            <div className="login-recommendations-categories" onClick={handleclickrecosstatus} id="TopLocations"> Top locations</div>
            <div className="login-recommendations-categories" onClick={handleclickrecosstatus} id="Frames"> Frames</div>
            </div>

            {stateRecommendations === "AllLocations" ? <RecommendationsAllLocations/> : null}
            {stateRecommendations === "NumberOfRecos" ? <RecommendationsNumberOfRecos/> : null}
            {stateRecommendations === "MinimalNumberOfRecos" ? <RecommendationsMinimalNumberOfRecos/> : null}
            {stateRecommendations === "Categories" ? <RecommendationsCategories/> : null}
            {stateRecommendations === "ExcludedLocations" ? <RecommendationsExcludedLocations/> : null}
            {stateRecommendations === "Websites" ? <RecommendationsWebsites/> : null}
            {stateRecommendations === "Images" ? <RecommendationsImages/> : null}
            {stateRecommendations === "TopLocations" ? <RecommendationsTopLocations/> : null}
            {stateRecommendations === "Frames" ? <RecommendationsFrames/> : null}
        </div>
    )
}

export default LoginRecommendations 