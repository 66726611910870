import React, { useContext }from "react";
import { clientdatacontext } from "./LoginValues.jsx";


function LoginStyles () {

// eslint-disable-next-line
const { clientdata, setClientdata } = useContext(clientdatacontext);

    return (
<div className="login-styles-container">
            <div className="login-styles-text">
            We are working on a styles page for {clientdata.clientname}...
            </div>
        </div>
    )

}

export default LoginStyles