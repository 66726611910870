import React, { useState, useEffect, useContext } from "react";
import '../CSS/DesignSix/DesignSixProgressBar.css';
import { designSixPagecounter, designSixMainCategories, designSixFavoriteInputsArray } from "./DesignSixValues.jsx";
import { APIdatacall } from "../AppValues.jsx";

function DesignSixProgressBar() {

      // eslint-disable-next-line
      const { APIdata, setAPIdata } = useContext(APIdatacall);
      const { designSixPage, setDesignSixPage } =  useContext(designSixPagecounter)
    const { designSixCategories, setDesignSixCategories } = useContext(designSixMainCategories);
    const { designSixFavoriteInputs, setDesignSixFavoriteInputs } = useContext(designSixFavoriteInputsArray);
    const [firstTime, setFirstTime] = useState(true);
    const [firstTimeInput, setFirstTimeInput] = useState(true);

    const [buttonfontsize, setButtonfontsize] = useState("");
    const [buttonmargintop, setButtonmargintop] = useState("");
        

    

    //WIDTH FIX START
    const [widthhappened, setWidthhappened] = useState(false)
    function getWindowDimensions() {
        const { innerWidth: width} = window;
        return {
          width,
        };
      }
      function handleResize() {
        var newwidth = (getWindowDimensions());
        if (newwidth.width >= 1024){
          setButtonfontsize(APIdata.styles.progressbar.progressbarbuttonsfontsizenormal);
          setButtonmargintop(APIdata.styles.progressbar.progressbarbuttonsmargintopnormal);
      } else if (newwidth.width >=720 && newwidth.width < 1024){
          setButtonfontsize(APIdata.styles.progressbar.progressbarbuttonsfontsizetablet);
          setButtonmargintop(APIdata.styles.progressbar.progressbarbuttonsmargintoptablet);
      } else if (newwidth.width < 720){        
          setButtonfontsize(APIdata.styles.progressbar.progressbarbuttonsfontsizemobile);
          setButtonmargintop(APIdata.styles.progressbar.progressbarbuttonsmargintopmobile);
      }}

      const [progresslinecolor, setProgresslinecolor] = useState();   
      function progresslinecolorfunction () {
      if (APIdata.styles.progressbar.progressbarlinecolor === undefined || APIdata.styles.progressbar.progressbarlinecolor === null){
          setProgresslinecolor(APIdata.styles.generalpage.colortwo)
      } else {
          setProgresslinecolor(APIdata.styles.progressbar.progressbarlinecolor)
      }}


      if ( widthhappened === false ) {
        handleResize()
        setWidthhappened(true)
        progresslinecolorfunction()
      }

    useEffect(() => {
        handleResize() 
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      });
    //WIDTH FIX END


    const [progresswidth, setProgresswidth] = useState("20%");   
    const [nextbuttontext, setNextButtonText] = useState(APIdata.styles.generalpage.nextbutton);   
    // eslint-disable-next-line 
    const [backbuttontext, setBackButtonText] = useState(APIdata.styles.generalpage.backbutton);   
    const [backbuttonstate, setBackButtonState] = useState("design-one-backbutton-inactive");   
    
    var fixedcategory;
    if (APIdata.styles.generalpage.fixedindex !== null){
        fixedcategory = APIdata.styles.generalpage.fixedindex;
    } else {
        fixedcategory = 100;
    }

    useEffect(() => {
        if (designSixPage === 2){
            setProgresswidth("20%")
            if (backbuttonstate === "design-four-backbutton"){
                setBackButtonState("design-four-backbutton-transition")
                setNextButtonText(APIdata.styles.generalpage.nextbutton)

            }
        } else if (designSixPage === 3){
            setProgresswidth("40%")
            setBackButtonState("design-four-backbutton")
            setNextButtonText(APIdata.styles.generalpage.nextbutton)

        } else if (designSixPage === 4 && firstTimeInput && designSixCategories.includes(fixedcategory)){
            setProgresswidth("40%")
            setBackButtonState("design-four-backbutton")
            setNextButtonText(APIdata.styles.generalpage.nextbutton)

        } else if (designSixPage === 4){
            setProgresswidth("60%")
            
            setNextButtonText(APIdata.styles.generalpage.wishlistbutton )
        } else if (designSixPage === 0){
            setProgresswidth("40%")
            setNextButtonText(APIdata.styles.generalpage.nextbutton)
            
        } else if (designSixPage === 5){
            setProgresswidth("100%")
            setNextButtonText(APIdata.styles.generalpage.againbutton)
        }
    },[designSixPage, backbuttonstate])

        function volgende () {
            if (designSixPage < 6){
                if (designSixPage !== 2 && designSixPage !== 3 && designSixPage !== 5){
            // eslint-disable-next-line
            var nextPage = designSixPage + 1;
            setDesignSixPage(nextPage)}

            if (designSixPage === 2 && designSixCategories.length !== 0){
                // eslint-disable-next-line
                var nextPage = designSixPage + 1;
                setDesignSixPage(nextPage)
            } else if (designSixPage === 2 && designSixCategories.length === 0) {
                alert(APIdata.styles.progressbar.categoryerror)
            }

           

                if (designSixPage === 3 && designSixFavoriteInputs.length !== 0){
                    // eslint-disable-next-line
                    var nextPage = designSixPage + 1;
                    setDesignSixPage(nextPage)
                } else if (designSixPage === 3 && designSixFavoriteInputs.length === 0) {
                    alert(APIdata.styles.progressbar.inputerror)
                }

                if (designSixPage === 5){
                    setDesignSixPage(2)
                    setNextButtonText("Volgende")
                    setDesignSixCategories([])
                    setDesignSixFavoriteInputs([])
                } 
                if (designSixPage === 2  && designSixCategories.length === 1 && designSixCategories.includes(fixedcategory)  ) {
                    var nextPage = designSixPage + 2;
                    setDesignSixPage(nextPage)
                   }
                
                }
        }
        function vorige () {
            if (designSixPage > 2){
            var lastPage = designSixPage - 1;
            setDesignSixPage(lastPage)}
            if (designSixPage === 4  && designSixCategories.length === 1 && designSixCategories.includes(fixedcategory)){
                var lastPage = designSixPage - 2;
                setDesignSixPage(lastPage)}

        }

        if (designSixPage === 2 && designSixCategories.length === 1 && firstTime ) {
           volgende(); // Call volgende() immediately when designSixCategories.length > 1
           setFirstTime(false);          
          }

          if (designSixPage === 3 && designSixCategories.length === 1 && firstTimeInput && designSixFavoriteInputs.length===1 ) {
            volgende(); // Call volgende() immediately when designSixCategories.length > 1
            setFirstTimeInput(false);          
           }

           
       
          
          



    return (
        <div className="design-four-progressbar"
        style={{
            backgroundColor: APIdata.styles.progressbar.progressbarbackgroundcolor
        }}>
        <div className="design-four-progressline"
        style={{
            width: progresswidth,
            backgroundColor: progresslinecolor
        }}>
        </div>
        <div className={backbuttonstate} onClick={vorige}
        style={{
            fontFamily: APIdata.styles.progressbar.progressbarbuttonsfont,
            fontSize: buttonfontsize,
            borderColor: APIdata.styles.progressbar.progressbarbordercolor
        }}>         
        <div className="design-four-backbutton-text"
        style={{
            fontFamily: APIdata.styles.progressbar.progressbarbuttonsfont,
            fontSize: buttonfontsize,
            top: buttonmargintop,
            color: APIdata.styles.progressbar.progressbarcolor
        }}>        
        {backbuttontext}
        </div>  
        </div>
        <div className="design-four-nextbutton" onClick={volgende}
        style={{
            backgroundColor: APIdata.styles.generalpage.colortwo,
            borderColor: APIdata.styles.generalpage.colortwo
        }}> 
        <div className="design-four-nextbutton-text"
        style={{
            fontFamily: APIdata.styles.progressbar.progressbarbuttonsfont,
            fontSize: buttonfontsize,
            top: buttonmargintop
        }}>        
        {nextbuttontext}
        </div>
        </div>
        <a href='../Files/Privacyverklaring Maps Untold.pdf' download target="_blank"
              style = {{ 
                position: "fixed",
                top: "100vh",
                marginTop: -15,
                zIndex: 3000,
                textDecorationLine : 'underline',
                color : 'gray',
                left: 5,
                cursor: 'pointer',                
                fontFamily: "montserrat",
                fontSize: 10,
              }}>
                Privacy Statement
              </a>
        </div>
    )
}

export default DesignSixProgressBar;