import React, { useState }from "react";
import RecommendationsCategoriesDisplay from "./RecommendationsCategoriesDisplay.jsx";
import RecommendationsCategoriesParentCategories from "./RecommendationsCategoriesParentCategories.jsx";
import RecommendationsCategoriesSubCategories from "./RecommendationsCategoriesSubCategories.jsx";
import RecommendationsCategoriesChildCategories from "./RecommendationsCategoriesChildCategories.jsx";
import RecommendationsCategoriesAlternatives from "./RecommendationsCategoriesAlternatives.jsx";


function RecommendationsCategories () {

    const [ categorypagestatus, setCategorypagestatus ] = useState("");

    function handleclickcategoriestatus(event) {
        var idofpage = event.target.id;
        setCategorypagestatus(idofpage)
    }


    return (
        <div className="login-recommendations-categories-categories-container">

        <div className="login-recommendations-categories-categories-buttons-container">
        <div id={"Overview"} onClick={handleclickcategoriestatus} className="login-recommendations-categories-categories-button"> Overview </div>
        <div id={"ParentCategories"} onClick={handleclickcategoriestatus} className="login-recommendations-categories-categories-button"> Parentcategories </div>
        <div id={"SubCategories"} onClick={handleclickcategoriestatus} className="login-recommendations-categories-categories-button"> Subcategories </div>
        <div id={"ChildCategories"} onClick={handleclickcategoriestatus} className="login-recommendations-categories-categories-button"> Childcategories </div>
        <div id={"Alternatives"} onClick={handleclickcategoriestatus} className="login-recommendations-categories-categories-button"> Alternatives </div>
        </div>

        { categorypagestatus === "Overview" ? <RecommendationsCategoriesDisplay/> : null }
        { categorypagestatus === "ParentCategories" ? <RecommendationsCategoriesParentCategories/> : null }
        { categorypagestatus === "SubCategories" ? <RecommendationsCategoriesSubCategories/> : null }
        { categorypagestatus === "ChildCategories" ? <RecommendationsCategoriesChildCategories/> : null }    
        { categorypagestatus === "Alternatives" ? <RecommendationsCategoriesAlternatives/> : null }    

        </div>
        )
    }

export default RecommendationsCategories