import React, { useContext, useState }from "react";
import { clientdatacontext } from "../LoginValues.jsx";


function RecommendationsImages () {

const { clientdata, setClientdata } = useContext(clientdatacontext);
const [ imageurlinput, setImageurlinput ] = useState("");

var listoflocationsinimagechangeslist = [];
    for (let i = 0; i < clientdata.imageChanges.length; i++) {
        listoflocationsinimagechangeslist.push(clientdata.imageChanges[i].locationid)
    }


    function inputhandler (event) {

        var indexvalue = event.target.value;
        setImageurlinput(indexvalue);
        };


    async function imagechangesfunction (event){
        var titleaddresssplit = event.target.id.split(" ");
        var action = titleaddresssplit[titleaddresssplit.length - 1];

        if (action === "add"){
            // eslint-disable-next-line
            var locationid = event.target.id.slice(0,-4)
            // const urlimageChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=imageChanges&titleaddress=' + titleaddress + '&action=add&imageurl=' + imageurlinput + '&apikey=' + clientdata.apikey);
            // const urlimageChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=imageChanges&titleaddress=' + titleaddress + '&action=add&imageurl=' + imageurlinput + '&apikey=' + clientdata.apikey);
            // const urlimageChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=imageChanges&titleaddress=' + titleaddress + '&action=add&imageurl=' + imageurlinput + '&apikey=' + clientdata.apikey);
            // const urlimageChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=imageChanges&locationid=' + locationid + '&action=add&imageurl=' + imageurlinput + '&apikey=' + clientdata.apikey);
            const urlimageChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=imageChanges&locationid=' + locationid + '&action=add&imageurl=' + imageurlinput + '&apikey=' + clientdata.apikey);
            // eslint-disable-next-line
            setImageurlinput("");
            var urlimageChangeswithoutrecos = await urlimageChangesnojson.json();
            // eslint-disable-next-line
             var urlimageChanges = {clientname: urlimageChangeswithoutrecos.clientname, city: urlimageChangeswithoutrecos.city, apikey: urlimageChangeswithoutrecos.apikey, apikey: urlimageChangeswithoutrecos.apikey, cityname: urlimageChangeswithoutrecos.cityname, excludedlocations: urlimageChangeswithoutrecos.excludedlocations, websiteChanges: urlimageChangeswithoutrecos.websiteChanges, imageChanges: urlimageChangeswithoutrecos.imageChanges, topChanges: urlimageChangeswithoutrecos.topChanges, inlijstingChanges: urlimageChangeswithoutrecos.inlijstingChanges, lengthRecos: urlimageChangeswithoutrecos.lengthRecos, categories: urlimageChangeswithoutrecos.categories, minimalrecos: urlimageChangeswithoutrecos.minimalrecos, status: urlimageChangeswithoutrecos.status, cityrecos: clientdata.cityrecos}
            setClientdata(urlimageChanges)
        } else if (action === "delete"){
            // eslint-disable-next-line
            var locationid = event.target.id.slice(0,-7)
            // const urlimageChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=imageChanges&titleaddress=' + titleaddress + '&action=delete&apikey=' + clientdata.apikey);
            // const urlimageChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=imageChanges&titleaddress=' + titleaddress + '&action=delete&apikey=' + clientdata.apikey);
            // const urlimageChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=imageChanges&titleaddress=' + titleaddress + '&action=delete&apikey=' + clientdata.apikey);
            // const urlimageChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=imageChanges&locationid=' + locationid + '&action=delete&apikey=' + clientdata.apikey);
            const urlimageChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=imageChanges&locationid=' + locationid + '&action=delete&apikey=' + clientdata.apikey);
            // eslint-disable-next-line
            var urlimageChangeswithoutrecos = await urlimageChangesnojson.json();
            // eslint-disable-next-line
             var urlimageChanges = {clientname: urlimageChangeswithoutrecos.clientname, city: urlimageChangeswithoutrecos.city, apikey: urlimageChangeswithoutrecos.apikey, apikey: urlimageChangeswithoutrecos.apikey, cityname: urlimageChangeswithoutrecos.cityname, excludedlocations: urlimageChangeswithoutrecos.excludedlocations, websiteChanges: urlimageChangeswithoutrecos.websiteChanges, imageChanges: urlimageChangeswithoutrecos.imageChanges, topChanges: urlimageChangeswithoutrecos.topChanges, inlijstingChanges: urlimageChangeswithoutrecos.inlijstingChanges, lengthRecos: urlimageChangeswithoutrecos.lengthRecos, categories: urlimageChangeswithoutrecos.categories, minimalrecos: urlimageChangeswithoutrecos.minimalrecos, status: urlimageChangeswithoutrecos.status, cityrecos: clientdata.cityrecos}
            setClientdata(urlimageChanges)
        }}


return (
    <div className="login-recommendations-categories-image-changes-locations-container">

        <div className="login-recommendations-categories-locations-image-changes-locations-list-included-header">Items in changed image list</div>

        {clientdata.cityrecos.map((item, index)=>(

        <div style={{ display: "inline-block"}} key={index}>
        {listoflocationsinimagechangeslist.includes(item._id) ? 

        <div className="login-recommendations-categories-location-container">
        <img src= {clientdata.imageChanges[clientdata.imageChanges.findIndex(x => x.locationid === item._id)].imageurl} alt="img" style={{width: 100, height:100}}/>
        <div className="login-recommendations-categories-location-text">{item.title.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.city.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.address.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.website.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.category.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-button" id={item._id + " delete"} onClick={imagechangesfunction}> delete from list </div> 
        </div> 
        : null }
        </div>
        ))}



        <div className="login-recommendations-categories-locations-image-changes-locations-list-excluded-header">Items not in changed image list</div>

        {clientdata.cityrecos.map((item, index)=>(
        <div style={{ display: "inline-block"}} key={index}>
        {listoflocationsinimagechangeslist.includes(item._id) ? 
        null :
        <div className="login-recommendations-categories-location-container">
        <img src= {item.imageURL} alt="img" style={{width: 100, height:100}}/>
        <div className="login-recommendations-categories-location-text">{item.title.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.city.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.address.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.website.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-text">{item.category.slice(0, 20)}</div>
        <div className="login-recommendations-categories-location-button" id={item._id + " add"} onClick={imagechangesfunction}> add to list </div> 
        </div> }
        </div>
        ))}

        <input className="login-recommendations-categories-locations-image-changes-inputbar"
        onChange = {inputhandler}
        value={imageurlinput}
        placeholder= {"Paste the link here and press the 'add to list' button"}
        autocomplete="off" autocorrect="off"
        ></input>

    </div>
)
}

export default RecommendationsImages
