import React, { useState, useContext, useEffect } from "react";
import '../CSS/DesignThree/DesignThreePageTwo.css';
import { designThreeMainCategories } from "./DesignThreeValues.jsx";
import { APIdatacall } from "../AppValues.jsx";

function DesignThreePageTwo() {


    const [animationhappened, setAnimationhappened] = useState(false);
    const [animationsecondpage, setAnimationsecondpage] = useState({left : {animation: 'x 0.3s', animationName: 'fadeInContent'}, right : {animation: 'x 0.4s', animationName: 'fadeInContent'}});
  
  
    // eslint-disable-next-line
  const { APIdata, setAPIdata } = useContext(APIdatacall);

    function animationchange () {
        if (animationhappened === false) {
            setAnimationhappened(true)
            setAnimationsecondpage({left : {animation: '', animationName: ''}, right : {animation: '', animationName: ''}})   
        }
    }

    var cityexplorertext;
    if (APIdata.styles.generalpage.cityexplorertext !== null){
        cityexplorertext = APIdata.styles.generalpage.cityexplorertext;
    } else {
        cityexplorertext = "Stadsontdekker " + APIdata.cityname;
    }

    var secondpageheaderleft;
    if (APIdata.styles.pagetwo.headerleft !== null){
        secondpageheaderleft = APIdata.styles.pagetwo.headerleft;
    } else {
        secondpageheaderleft = "Wat wil je gaan bezoeken?";
    }

    var secondpagesubheaderleft;
    if (APIdata.styles.pagetwo.subheaderleft !== null){
        secondpagesubheaderleft = APIdata.styles.pagetwo.subheaderleft;
    } else {
        secondpagesubheaderleft = "Uitgebreid dineren, op street art tocht gaan, koffieleuten, rondneuzen in de nieuwe kunstgallerij of vertroeteld worden bij die ene cozy Bed and Breakfast?";
    }
 
    var secondpageheader;
    if (APIdata.styles.pagetwo.headerright !== null){
        secondpageheader = APIdata.styles.pagetwo.headerright;
    } else {
        secondpageheader = "Wat wil je doen?";
    }

    var secondpagesubheader;
    if (APIdata.styles.pagetwo.subheaderright !== null){
        secondpagesubheader = APIdata.styles.pagetwo.subheaderright;
    } else {
        secondpagesubheader = null;
    }

    var secondpageheaderfont;
    if (APIdata.styles.pagetwo.rightheaderfont !== null){
        secondpageheaderfont = APIdata.styles.pagetwo.rightheaderfont;
    } else {
        secondpageheaderfont = null;
    }

    var secondpagesubheaderfont;
    if (APIdata.styles.pagetwo.rightsubheaderfont !== null){
        secondpagesubheaderfont = APIdata.styles.pagetwo.rightsubheaderfont;
    } else {
        secondpagesubheaderfont = null;
    }

    var secondpagecategorytilefont;
    if (APIdata.styles.pagetwo.categorytilefont !== null){
        secondpagecategorytilefont = APIdata.styles.pagetwo.categorytilefont;
    } else {
        secondpagecategorytilefont = null;
    }

    var colorone;
    if (APIdata.styles.generalpage.colorone !== null){
        colorone = APIdata.styles.generalpage.colorone;
    } else {
        colorone = "#264f73";
    }

    const [fontheightheader, setFontheightheader] = useState("");
    const [fontheightsubheader, setFontheightsubheader] = useState("");
    const [lineheightsubheader, setLineheightsubheader] = useState("");
    const [stadsontdekkersize, setStadsontdekkersize] = useState("");
    const [stadsontdekkermapsuntoldfontsize, setStadsontdekkermapsuntoldfontsize] = useState("");
    const [stadsontdekkermapsuntoldmargintop, setStadsontdekkermapsuntoldmargintop] = useState("");
    const [stadsontdekkertextmargintop, setStadsontdekkertextmargintop] = useState("");
    const [rightheaderfontsize, setRightheaderfontsize] = useState("");
    const [rightsubheaderfontsize, setRightsubheaderfontsize] = useState("");
    const [secondpagecategorytilefontsize, setSecondpagecategorytilefontsize] = useState("");


//WIDTH FIX START
const [widthhappened, setWidthhappened] = useState(false)
function getWindowDimensions() {
    const { innerWidth: width} = window;
    return {
      width,
    };
  }
  function handleResize() {
    var newwidth = (getWindowDimensions());
    if (newwidth.width >= 1024){
        setFontheightheader(APIdata.styles.pagetwo.headerleftfontsizenormal);
        setFontheightsubheader(APIdata.styles.pagetwo.subheaderleftfontsizenormal);
        setLineheightsubheader(APIdata.styles.pagetwo.subheaderleftlineheightnormal);
        setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizenormal);
        setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizenormal);
        setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintopnormal);
        setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintopnormal);
        setRightheaderfontsize(APIdata.styles.pagetwo.rightheaderfontsizenormal);
        setRightsubheaderfontsize(APIdata.styles.pagetwo.rightsubheaderfontsizenormal);
        setSecondpagecategorytilefontsize(APIdata.styles.pagetwo.categorytilefontsizenormal);
    } else if (newwidth.width >=720 && newwidth.width < 1024){
        setFontheightheader(APIdata.styles.pagetwo.headerleftfontsizetablet);
        setFontheightsubheader(APIdata.styles.pagetwo.subheaderleftfontsizetablet);
        setLineheightsubheader(APIdata.styles.pagetwo.subheaderleftlineheighttablet);
        setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizetablet);
        setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizetablet);
        setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintoptablet);
        setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintoptablet);
        setRightheaderfontsize(APIdata.styles.pagetwo.rightheaderfontsizetablet);
        setRightsubheaderfontsize(APIdata.styles.pagetwo.rightsubheaderfontsizetablet);
        setSecondpagecategorytilefontsize(APIdata.styles.pagetwo.categorytilefontsizetablet);
    } else if (newwidth.width < 720){        
        setFontheightheader(APIdata.styles.pagetwo.headerleftfontsizemobile);
        setFontheightsubheader(APIdata.styles.pagetwo.subheaderleftfontsizemobile);
        setLineheightsubheader(APIdata.styles.pagetwo.subheaderleftlineheightmobile);
        setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizemobile);
        setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizemobile);
        setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintopmobile);
        setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintopmobile);
        setRightheaderfontsize(APIdata.styles.pagetwo.rightheaderfontsizemobile);
        setRightsubheaderfontsize(APIdata.styles.pagetwo.rightsubheaderfontsizemobile);
        setSecondpagecategorytilefontsize(APIdata.styles.pagetwo.categorytilefontsizemobile);
    }}

    if ( widthhappened === false ) {
        handleResize()
        setWidthhappened(true)
    }

    useEffect(() => {
        handleResize() 
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    //WIDTH FIX END


//RIGHT SIDE
function Categoriesdisplay () {
    const { designThreeCategories, setDesignThreeCategories } = useContext(designThreeMainCategories);
    function handleClick (index) {
        console.log(designThreeCategories)
        console.log(index.index)
        //setDesignThreeCategories(designThreeCategories => [...designThreeCategories, index.index] );
        if (designThreeCategories.includes(index.index)){
            setDesignThreeCategories(designThreeCategories.filter((i) => index.index !== i ));
        } else {
            setDesignThreeCategories(designThreeCategories => [...designThreeCategories, index.index] );
        }
    }

    const data = APIdata.categories

    return (
        <div className="design-four-page-two-right-categories-container"
            style={{
                animation: animationsecondpage.right.animation,
                animationName: animationsecondpage.right.animationName,
        }}>
        <div className="design-four-page-two-right-header"
        style={{fontFamily: secondpageheaderfont,
        fontSize: rightheaderfontsize,
        }}>
        {secondpageheader}
        </div>
        <div className="design-four-page-two-right-subheader"
        style={{fontFamily: secondpagesubheaderfont,
        fontSize: rightsubheaderfontsize,}}>
        {secondpagesubheader}
        </div>
            {data.map((category, index)=>(
            <div className="design-four-page-two-right-category-container" 
            key= {index} 
            id= {index}
            style={{}}  
            value = {index}
            onClick={() => handleClick({index})}
            >
            {designThreeCategories.includes(index) ? 
            <div className="design-four-page-two-right-category-part" 
            style={{backgroundColor: colorone, borderColor: colorone, color: "white", fontFamily: secondpagecategorytilefont, fontSize: secondpagecategorytilefontsize}}>
            <img src= {category.images.light} alt="img" 
            style={{display: "block"}} 
            className="design-four-page-two-right-category-image"/>
            {category.parentcategory}
            </div> 
            : 
            <div className="design-four-page-two-right-category-part" style={{fontFamily: secondpagecategorytilefont, fontSize: secondpagecategorytilefontsize}}>
            <img src= {category.images.dark} alt="img" 
            style={{display: "block"}} 
            className="design-four-page-two-right-category-image"/>
            {category.parentcategory}
            </div>}    
            </div>
            ))}    
        </div>
    )};


    return (
        <div className="design-four-page-two-body" onClick={animationchange}>
            
        <div className="design-four-page-two-left-body"
        style={{
            backgroundColor: APIdata.styles.generalpage.leftcolor,
            paddingBottom: APIdata.styles.pagetwo.subheaderleftfontsizemobile
        }}>

        <div className="design-four-page-two-left-cityexplorer"
        style={{
            marginTop: APIdata.styles.generalpage.bartopheight,
            paddingTop: APIdata.styles.generalpage.citydiscoveryheight
        }}>
        <div className="design-four-page-two-left-cityexplorer-logo">
        <img src={require("../images/maps-untold-logo-small.png")} alt="logo" className="design-four-page-two-left-cityexplorer-logo-image"/>
        </div>
        <div className="design-four-page-two-left-cityexplorer-mapsuntold">
        <div className="design-four-page-two-left-cityexplorer-mapsuntold-text"
        style={{
            fontFamily: APIdata.styles.generalpage.stadsontdekkermapsuntoldfont,
            fontSize: stadsontdekkermapsuntoldfontsize,
            marginTop: stadsontdekkermapsuntoldmargintop
        }}>
        Maps Untold
        </div>
        </div>
        <div className="design-four-page-two-left-cityexplorer-line">
        </div>
        <div className="design-four-page-two-left-cityexplorer-city">
        <div className="design-four-page-two-left-cityexplorer-city-text"
        style={{
            fontFamily: APIdata.styles.generalpage.stadsontdekkertextfont,
            color: APIdata.styles.generalpage.colorone,
            fontSize: stadsontdekkersize,
            marginTop: stadsontdekkertextmargintop
        }}>
        {cityexplorertext}
        </div>
        </div>
        </div>

        <div className="design-four-page-two-left-header" 
        style={{
            fontFamily:  APIdata.styles.generalpage.headerleftfont,
            fontSize: fontheightheader,
            color: APIdata.styles.generalpage.headerleftcolor,
            animation: animationsecondpage.left.animation,
            animationName: animationsecondpage.left.animationName
        }}>
        {secondpageheaderleft}
        </div>
        <div className="design-four-page-two-left-subheader"
        style={{
            fontFamily:  APIdata.styles.generalpage.subheaderleftfont,
            fontSize: fontheightsubheader,
            color: APIdata.styles.generalpage.subheaderleftcolor,
            lineHeight: lineheightsubheader,
            animation: animationsecondpage.left.animation,
            animationName: animationsecondpage.left.animationName
        }}>
        {secondpagesubheaderleft}

        </div>
        </div>

        <div className="design-four-page-two-right-body"
        style={{
            backgroundColor: APIdata.styles.generalpage.rightcolor,
        }}>
        <div className="design-four-page-two-right-first-container" style={{
                    marginTop: APIdata.styles.pagetwo.rightmargintop,
            }}>
        </div>

        <Categoriesdisplay/>

        </div>

        </div>
    )
}

export default DesignThreePageTwo;