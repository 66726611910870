import React, { useContext }from "react";
import { clientdatacontext } from "../LoginValues.jsx";


function RecommendationsAllLocations () {

// eslint-disable-next-line
    const { clientdata, setClientdata } = useContext(clientdatacontext);

    return (
        <div className="login-recommendations-categories-all-locations-container">
            {clientdata.cityrecos.map((item, index)=>(
            <div className="login-recommendations-categories-location-container" key={index}>
            <img src= {item.imageURL} alt="img" style={{width: 100, height:100}}/>
            <div style={{marginTop: 5}}>{item.title.slice(0, 20)}</div>
            <div style={{marginTop: 5}}>{item.city.slice(0, 20)}</div>
            <div style={{marginTop: 5}}>{item.address.slice(0, 20)}</div>
            <div style={{marginTop: 5}}>{item.website.slice(0, 20)}</div>
            <div style={{marginTop: 5}}>{item.category.slice(0, 20)}</div>
            </div>
            ))}
        </div>
    )
}

export default RecommendationsAllLocations