import React, { useState, useEffect ,useRef } from "react"
import DesignOne from './DesignOne.jsx';
import DesignTwo from './DesignTwo.jsx';
import DesignThree from './DesignThree.jsx';
import DesignFour from './DesignFour.jsx';
import DesignSix from './DesignSix.jsx';
import DesignSeven from './DesignSeven.jsx';

import { designOnePagecounter, designOneMainCategories, designOneFavoriteInputsArray } from "./DesignOne/DesignOneValues.jsx";
import { designTwoPagecounter, designTwoMainCategories, designTwoFavoriteInputsArray } from "./DesignTwo/DesignTwoValues.jsx";
import { designThreePagecounter, designThreeMainCategories, designThreeFavoriteInputsArray, designThreeWishlistItemsArray } from "./DesignThree/DesignThreeValues.jsx";
import { designFourPagecounter, designFourMainCategories, designFourFavoriteInputsArray, designFourWishlistItemsArray } from "./DesignFour/DesignFourValues.jsx";
import { designSixPagecounter, designSixMainCategories, designSixFavoriteInputsArray, designSixWishlistItemsArray } from "./DesignSix/DesignSixValues.jsx";
import { designSevenPagecounter, designSevenMainCategories, designSevenFavoriteInputsArray, designSevenWishlistItemsArray } from "./DesignSeven/DesignSevenValues.jsx";

import {  APIdatacall } from "./AppValues.jsx";
import { useLocation } from 'react-router-dom'
import './CSS/Fonts/fonts.css';
import './CSS/AppCSS.css'




function App (){

    const [designOnePage, setDesignOnePage] = useState(1)
    const [designOneCategories, setDesignOneCategories] = useState([])
    const [designOneFavoriteInputs, setDesignOneFavoriteInputs] = useState([])

    const [designTwoPage, setDesignTwoPage] = useState(1)
    const [designTwoCategories, setDesignTwoCategories] = useState([])
    const [designTwoFavoriteInputs, setDesignTwoFavoriteInputs] = useState([])

    // set first page number
    const [designThreePage, setDesignThreePage] = useState(3)
    const [designThreeCategories, setDesignThreeCategories] = useState([0])
    const [designThreeFavoriteInputs, setDesignThreeFavoriteInputs] = useState([])
    const [designThreeWishlistItems, setDesignThreeWishlistItems] = useState([])

    const [designFourPage, setDesignFourPage] = useState(1)
    const [designFourCategories, setDesignFourCategories] = useState([])
    const [designFourFavoriteInputs, setDesignFourFavoriteInputs] = useState([])
    const [designFourWishlistItems, setDesignFourWishlistItems] = useState([])

    const [designSixPage, setDesignSixPage] = useState(2)
    const [designSixCategories, setDesignSixCategories] = useState([])
    const [designSixFavoriteInputs, setDesignSixFavoriteInputs] = useState([])
    const [designSixWishlistItems, setDesignSixWishlistItems] = useState([])
    


    // const [APIdatahappened, setAPIdatahappened] = useState(false)
    const [APIdata, setAPIdata] = useState("")
    var design;

    const location = useLocation();
    var clientname = location.pathname.toLowerCase();
    var client = clientname.replace("/", "");

    async function apicall () {
        // const apicallnojson = await fetch('https://api-mapsuntold.nl/frontend?clientname=' + client + '&type=0')
        const apicallnojson = await fetch('https://api-mapsuntold.nl/frontend?clientname=' + client + '&type=0')
        //const apicallnojson = await fetch('https://api-mapsuntold.nl/frontend?clientname=' + client + '&type=0')
        var apicall = await apicallnojson.json();
        var apicallstring = await apicall.message
        setAPIdata(apicallstring)
    }
    const [designSevenPage, setDesignSevenPage] = useState(2)
    const [designSevenCategories, setDesignSevenCategories] = useState([])
    const [designSevenFavoriteInputs, setDesignSevenFavoriteInputs] = useState([])
    const [designSevenWishlistItems, setDesignSevenWishlistItems] = useState([])
    const shouldLog = useRef(true);
    useEffect(()=>{
        if (shouldLog.current){
            shouldLog.current = false;
            apicall ();
        }
    })


        // if (APIdatahappened === false) {
        // apicall ();
        // setAPIdatahappened(true)
        // } 
        

        if (APIdata === ""){
            return ( <div className="lds-dual-ring"></div>)
        } else {
            design = APIdata.design
        

    return (
        <div style={{
            overflow: "hidden"
            }}>

        <APIdatacall.Provider value = {{ APIdata, setAPIdata }}>

        <designOnePagecounter.Provider value = {{ designOnePage, setDesignOnePage }}>
        <designOneMainCategories.Provider value = {{ designOneCategories, setDesignOneCategories }}>
        <designOneFavoriteInputsArray.Provider value = {{ designOneFavoriteInputs, setDesignOneFavoriteInputs }}>

        {design === '5' ? <DesignOne/> : null}    {/* Change 2023Q1 sets design six to occur*/}

        </designOneFavoriteInputsArray.Provider>
        </designOneMainCategories.Provider>
        </designOnePagecounter.Provider>

        <designTwoPagecounter.Provider value = {{ designTwoPage, setDesignTwoPage }}>
        <designTwoMainCategories.Provider value = {{ designTwoCategories, setDesignTwoCategories }}>
        <designTwoFavoriteInputsArray.Provider value = {{ designTwoFavoriteInputs, setDesignTwoFavoriteInputs }}>

        {design === '6' ? <DesignTwo/> : null}

        </designTwoFavoriteInputsArray.Provider>
        </designTwoMainCategories.Provider>
        </designTwoPagecounter.Provider>

        <designThreePagecounter.Provider value = {{ designThreePage, setDesignThreePage }}>
        <designThreeMainCategories.Provider value = {{ designThreeCategories, setDesignThreeCategories }}>
        <designThreeFavoriteInputsArray.Provider value = {{ designThreeFavoriteInputs, setDesignThreeFavoriteInputs }}>
        <designThreeWishlistItemsArray.Provider value = {{ designThreeWishlistItems, setDesignThreeWishlistItems }}>

        {design === '3' ? <DesignThree/> : null}

        </designThreeWishlistItemsArray.Provider>
        </designThreeFavoriteInputsArray.Provider>
        </designThreeMainCategories.Provider>
        </designThreePagecounter.Provider>

        <designFourPagecounter.Provider value = {{ designFourPage, setDesignFourPage }}>
        <designFourMainCategories.Provider value = {{ designFourCategories, setDesignFourCategories }}>
        <designFourFavoriteInputsArray.Provider value = {{ designFourFavoriteInputs, setDesignFourFavoriteInputs }}>
        <designFourWishlistItemsArray.Provider value = {{ designFourWishlistItems, setDesignFourWishlistItems }}>

        {design === '4' ? <DesignFour/> : null}

        </designFourWishlistItemsArray.Provider>
        </designFourFavoriteInputsArray.Provider>
        </designFourMainCategories.Provider>
        </designFourPagecounter.Provider>

        <designSixPagecounter.Provider value = {{ designSixPage, setDesignSixPage }}>
        <designSixMainCategories.Provider value = {{ designSixCategories, setDesignSixCategories }}>
        <designSixFavoriteInputsArray.Provider value = {{ designSixFavoriteInputs, setDesignSixFavoriteInputs }}>
        <designSixWishlistItemsArray.Provider value = {{ designSixWishlistItems, setDesignSixWishlistItems }}>

        {design === '1' ? <DesignSix/> : null}
        </designSixWishlistItemsArray.Provider>

        </designSixFavoriteInputsArray.Provider>
        </designSixMainCategories.Provider>
        </designSixPagecounter.Provider>

        <designSevenPagecounter.Provider value = {{ designSevenPage, setDesignSevenPage }}>
        <designSevenMainCategories.Provider value = {{ designSevenCategories, setDesignSevenCategories }}>
        <designSevenFavoriteInputsArray.Provider value = {{ designSevenFavoriteInputs, setDesignSevenFavoriteInputs }}>
        <designSevenWishlistItemsArray.Provider value = {{ designSevenWishlistItems, setDesignSevenWishlistItems }}>

        {design === '2' ? <DesignSeven/> : null}
        </designSevenWishlistItemsArray.Provider>

        </designSevenFavoriteInputsArray.Provider>
        </designSevenMainCategories.Provider>
        </designSevenPagecounter.Provider>

        </APIdatacall.Provider>

        </div>
    
    )
        }
}

export default App
