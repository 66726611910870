import React, { useContext } from "react";
import DesignFourBarTop from "./DesignFour/DesignFourBarTop";
import DesignFourPageOne from './DesignFour/DesignFourPageOne.jsx';
import DesignFourPageTwo from './DesignFour/DesignFourPageTwo.jsx';
import DesignFourPageThree from './DesignFour/DesignFourPageThree.jsx';
import DesignFourPageFour from './DesignFour/DesignFourPageFour.jsx';
import DesignFourPageFive from './DesignFour/DesignFourPageFive.jsx';
import DesignFourProgressBar from "./DesignFour/DesignFourProgressBar.jsx";
import { designFourPagecounter } from "./DesignFour/DesignFourValues.jsx";


function designFour() {
    
    
        // eslint-disable-next-line
        const { designFourPage, setDesignFourPage } =  useContext(designFourPagecounter)

        return (
            <div>
            <DesignFourBarTop/>
            {designFourPage === 1 ? <DesignFourPageOne/> : null}
            {designFourPage === 2 ? <DesignFourPageTwo/> : null}
            {designFourPage === 3 ? <DesignFourPageThree/> : null}
            {designFourPage === 4 ? <DesignFourPageFour/> : null}
            {designFourPage === 5 ? <DesignFourPageFive/> : null}
            <DesignFourProgressBar/>
            </div>
        )
    }

export default designFour;