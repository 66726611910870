import React, { useContext }from "react";
import { clientdatacontext } from "./LoginValues.jsx";


function LoginDashboard () {

// eslint-disable-next-line
const { clientdata, setClientdata } = useContext(clientdatacontext);

    return (


        <div className="login-dashboard-container">
            <div className="login-dashboard-text">
            We are working on a dashboard for {clientdata.clientname}...
            </div>
        </div>
    )

}

export default LoginDashboard