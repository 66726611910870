import React, { useContext }from "react";
import { clientdatacontext } from "../LoginValues.jsx";


function RecommendationsFrames () {

    const { clientdata, setClientdata } = useContext(clientdatacontext);

    console.log(clientdata);

    var listoflocationsinframeschangeslist = [];
    for (let i = 0; i < clientdata.inlijstingChanges.length; i++) {
        listoflocationsinframeschangeslist.push(clientdata.inlijstingChanges[i].locationid)
    }

    async function frameschangesfunction (event){
        var titleaddresssplit = event.target.id.split(" ");
        var action = titleaddresssplit[titleaddresssplit.length - 1];

        if (action === "add"){
            // eslint-disable-next-line
            var locationid = event.target.id.slice(0,-4)
            var framesnumber = document.getElementById(event.target.id.slice(0,-4) + " input").value.toString()
            // eslint-disable-next-line
            // const urlframesChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=inlijstingChanges&titleaddress=' + titleaddress + '&action=add&inlijsting=' + framesnumber + '&apikey=' + clientdata.apikey);
            // const urlframesChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=inlijstingChanges&titleaddress=' + titleaddress + '&action=add&inlijsting=' + framesnumber + '&apikey=' + clientdata.apikey);
            // const urlframesChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=inlijstingChanges&locationid=' + locationid + '&action=add&inlijsting=' + framesnumber + '&apikey=' + clientdata.apikey);
            const urlframesChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=inlijstingChanges&locationid=' + locationid + '&action=add&inlijsting=' + framesnumber + '&apikey=' + clientdata.apikey);
            // eslint-disable-next-line
            var urlframesChangeswithoutrecos = await urlframesChangesnojson.json();
            // eslint-disable-next-line
             var urlframesChanges = {clientname: urlframesChangeswithoutrecos.clientname, city: urlframesChangeswithoutrecos.city, apikey: urlframesChangeswithoutrecos.apikey, cityname: urlframesChangeswithoutrecos.cityname, excludedlocations: urlframesChangeswithoutrecos.excludedlocations, websiteChanges: urlframesChangeswithoutrecos.websiteChanges, imageChanges: urlframesChangeswithoutrecos.imageChanges, topChanges: urlframesChangeswithoutrecos.topChanges, inlijstingChanges: urlframesChangeswithoutrecos.inlijstingChanges, lengthRecos: urlframesChangeswithoutrecos.lengthRecos, categories: urlframesChangeswithoutrecos.categories, minimalrecos: urlframesChangeswithoutrecos.minimalrecos, status: urlframesChangeswithoutrecos.status, cityrecos: clientdata.cityrecos}
            setClientdata(urlframesChanges)
        } else if (action === "delete"){
            // eslint-disable-next-line
            var locationid = event.target.id.slice(0,-7)
            // eslint-disable-next-line
            // const urlframesChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=inlijstingChanges&titleaddress=' + titleaddress + '&action=delete' + '&apikey=' + clientdata.apikey);
            // const urlframesChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=inlijstingChanges&titleaddress=' + titleaddress + '&action=delete&apikey=' + clientdata.apikey);
            // const urlframesChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=inlijstingChanges&locationid=' + locationid + '&action=delete&apikey=' + clientdata.apikey);
            const urlframesChangesnojson = await fetch('https://api-mapsuntold.nl/action?clientname=' + clientdata.clientname + '&category=recommendations&subcategory=inlijstingChanges&locationid=' + locationid + '&action=delete&apikey=' + clientdata.apikey);
            // eslint-disable-next-line
            var urlframesChangeswithoutrecos = await urlframesChangesnojson.json();
            // eslint-disable-next-line
             var urlframesChanges = {clientname: urlframesChangeswithoutrecos.clientname, city: urlframesChangeswithoutrecos.city, apikey: urlframesChangeswithoutrecos.apikey, cityname: urlframesChangeswithoutrecos.cityname, excludedlocations: urlframesChangeswithoutrecos.excludedlocations, websiteChanges: urlframesChangeswithoutrecos.websiteChanges, imageChanges: urlframesChangeswithoutrecos.imageChanges, topChanges: urlframesChangeswithoutrecos.topChanges, inlijstingChanges: urlframesChangeswithoutrecos.inlijstingChanges, lengthRecos: urlframesChangeswithoutrecos.lengthRecos, categories: urlframesChangeswithoutrecos.categories, minimalrecos: urlframesChangeswithoutrecos.minimalrecos, status: urlframesChangeswithoutrecos.status, cityrecos: clientdata.cityrecos}
            setClientdata(urlframesChanges)
        }}

    return (    
        <div className="login-recommendations-categories-frames-changes-locations-container">
            <div className="login-recommendations-categories-locations-frames-changes-locations-list-included-header">Items in frames list</div>

            {clientdata.cityrecos.map((item, index)=>(
    
            <div style={{ display: "inline-block"}} key={index}>
            {listoflocationsinframeschangeslist.includes(item._id) ? 
            <div className="login-recommendations-categories-location-container">
            <img src= {item.imageURL} alt="img" style={{width: 100, height:100}}/>
            <div className="login-recommendations-categories-location-text-frames">{item.title.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text-frames">{item.city.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text-frames">{item.address.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text-frames">{item.website.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text-frames">{item.category.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text-frames"> frame: {clientdata.inlijstingChanges[clientdata.inlijstingChanges.findIndex(x => x.locationid === item._id)].inlijsting.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-button" id={item._id + " delete"} onClick={frameschangesfunction}> delete from list </div> 
            </div> 
            : null }
            </div>
            ))}
    
    
    
            <div className="login-recommendations-categories-locations-frames-changes-locations-list-excluded-header">Items not in frames list</div>
    
            {clientdata.cityrecos.map((item, index)=>(
            <div style={{ display: "inline-block"}} key={index}>
            {listoflocationsinframeschangeslist.includes(item._id) ? 
            null :
            <div className="login-recommendations-categories-location-container">
            <img src= {item.imageURL} alt="img" style={{width: 100, height:100}}/>
            <div className="login-recommendations-categories-location-text">{item.title.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.city.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.address.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.website.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-text">{item.category.slice(0, 20)}</div>
            <div className="login-recommendations-categories-location-button-frames" id={item._id + " add"} onClick={frameschangesfunction}> add to list </div> 
            <input className="login-recommendations-categories-location-input-frames" id={item._id + " input"} type = "number"></input>
            </div> }
            </div>
            ))}
            </div>
)
}

export default RecommendationsFrames
