import React, { useContext } from "react";
import '../CSS/DesignTwo/DesignTwoBarTop.css';

import { APIdatacall } from "../AppValues.jsx";

function DesignTwoBarTop() {

    // eslint-disable-next-line
    const { APIdata, setAPIdata } = useContext(APIdatacall);

    return (
        <div className="design-two-bartop"
        style={{
            height: APIdata.styles.generalpage.bartopheight,
            backgroundColor: APIdata.styles.bartop.bartopbackgroundcolor
        }}>
        </div>
    )
}

export default DesignTwoBarTop;