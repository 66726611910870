import React, { useContext } from "react";
import DesignTwoBarTop from "./DesignTwo/DesignTwoBarTop";
import DesignTwoPageOne from './DesignTwo/DesignTwoPageOne.jsx';
import DesignTwoPageTwo from './DesignTwo/DesignTwoPageTwo.jsx';
import DesignTwoPageThree from './DesignTwo/DesignTwoPageThree.jsx';
import DesignTwoPageFour from './DesignTwo/DesignTwoPageFour.jsx';
import DesignTwoProgressBar from "./DesignTwo/DesignTwoProgressBar.jsx";
import { designTwoPagecounter } from "./DesignTwo/DesignTwoValues.jsx";

function DesignTwo() {

    // eslint-disable-next-line
    const { designTwoPage, setDesignTwoPage } =  useContext(designTwoPagecounter)

    return (
        <div>
        <DesignTwoBarTop/>
        {designTwoPage === 1 ? <DesignTwoPageOne/> : null}
        {designTwoPage === 2 ? <DesignTwoPageTwo/> : null}
        {designTwoPage === 3 ? <DesignTwoPageThree/> : null}
        {designTwoPage === 4 ? <DesignTwoPageFour/> : null}
        <DesignTwoProgressBar/>
        </div>
    )
}

export default DesignTwo;