import React, { useState, useContext, useEffect } from "react";
import '../CSS/DesignFour/DesignFourPageOne.css';
import { APIdatacall } from "../AppValues.jsx";

function DesignFourPageOne() {

    const [animationhappened, setAnimationhappened] = useState(false);
    const [animationfirstpage, setAnimationfirstpage] = useState({left : {animation: 'x 0.3s', animationName: 'fadeInContent'}, right : {animation: 'x 0.4s', animationName: 'fadeInContent'}});


  // eslint-disable-next-line
  const { APIdata, setAPIdata } = useContext(APIdatacall);

    function animationchange () {
        if (animationhappened === false) {
            setAnimationhappened(true)
            setAnimationfirstpage({left : {animation: '', animationName: ''}, right : {animation: '', animationName: ''}})   
        }
    }


    var cityexplorertext;
    if (APIdata.styles.generalpage.cityexplorertext !== null){
        cityexplorertext = APIdata.styles.generalpage.cityexplorertext;
    } else {
        cityexplorertext = "Stadsontdekker " + APIdata.cityname;
    }

    var firstpageheaderleft;
    if (APIdata.styles.pageone.headerleft !== null){
        firstpageheaderleft = APIdata.styles.pageone.headerleft;
    } else {
        firstpageheaderleft = "Ontdek " + APIdata.cityname + ", op jouw manier";
    }

    var firstpagesubheaderleft;
    if (APIdata.styles.pageone.subheaderleft !== null){
        firstpagesubheaderleft = APIdata.styles.pageone.subheaderleft;
    } else {
        firstpagesubheaderleft = "Beleef jouw ultieme bezoek aan " + APIdata.cityname + " met de Stadsontdekker. Op basis van lokale reviews matchen wij de best passende plekken aan jou om te bezoeken.";
    }

    const [fontheightheader, setFontheightheader] = useState("");
    const [fontheightsubheader, setFontheightsubheader] = useState("");
    const [lineheightsubheader, setLineheightsubheader] = useState("");
    const [stadsontdekkersize, setStadsontdekkersize] = useState("");
    const [stadsontdekkermapsuntoldfontsize, setStadsontdekkermapsuntoldfontsize] = useState("");
    const [stadsontdekkermapsuntoldmargintop, setStadsontdekkermapsuntoldmargintop] = useState("");
    const [stadsontdekkertextmargintop, setStadsontdekkertextmargintop] = useState("");
    const [slidenumber, setSlidenumber] = useState(0);
    const [slidewidth, setSlidewidth] = useState(true);


        //WIDTH FIX START
        const [widthhappened, setWidthhappened] = useState(false)
        function getWindowDimensions() {
            const { innerWidth: width} = window;
            return {
              width,
            };
          }
          function handleResize() {
            var newwidth = (getWindowDimensions());
            if (newwidth.width >= 1024){
                setFontheightheader(APIdata.styles.pageone.headerleftfontsizenormal);
                setFontheightsubheader(APIdata.styles.pageone.subheaderleftfontsizenormal);
                setLineheightsubheader(APIdata.styles.pageone.subheaderleftlineheightnormal);
                setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizenormal);
                setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizenormal);
                setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintopnormal);
                setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintopnormal);
                setSlidewidth(true);
          } else if (newwidth.width >=720 && newwidth.width < 1024){
                setFontheightheader(APIdata.styles.pageone.headerleftfontsizetablet);
                setFontheightsubheader(APIdata.styles.pageone.subheaderleftfontsizetablet);
                setLineheightsubheader(APIdata.styles.pageone.subheaderleftlineheighttablet);
                setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizetablet);
                setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizetablet);
                setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintoptablet);
                setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintoptablet);
                setSlidewidth(false);
          } else if (newwidth.width < 720){        
                setFontheightheader(APIdata.styles.pageone.headerleftfontsizemobile);
                setFontheightsubheader(APIdata.styles.pageone.subheaderleftfontsizemobile);
                setLineheightsubheader(APIdata.styles.pageone.subheaderleftlineheightmobile);
                setStadsontdekkersize(APIdata.styles.generalpage.stadsontdekkertextfontsizemobile);
                setStadsontdekkermapsuntoldfontsize(APIdata.styles.generalpage.stadsontdekkermapsuntoldfontsizemobile);
                setStadsontdekkermapsuntoldmargintop(APIdata.styles.generalpage.stadsontdekkermapsuntoldmargintopmobile);
                setStadsontdekkertextmargintop(APIdata.styles.generalpage.stadsontdekkertextmargintopmobile);
                setSlidewidth(false);
          }}
          if ( widthhappened === false ) {
            handleResize()
            setWidthhappened(true)
          }
        useEffect(() => {
            handleResize() 
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
          });
        //WIDTH FIX END

        setTimeout(() => {
            if (slidenumber === (APIdata.styles.pageone.slideshow.length - 1)){
                setSlidenumber(0)
            } else {
            setSlidenumber(slidenumber + 1)
        }}, 4000);



          function Slideshow () {


            var lijstslideshow = [];
            if (APIdata.styles.pageone.slideshow !== undefined){
            for (let i = 0; i < APIdata.styles.pageone.slideshow.length; i++){
                var lijstslideshowpictures = [];
                for (let j = 0; j < APIdata.styles.pageone.slideshow[i].pictures.length; j++){
                    lijstslideshowpictures.push(<div key={j} className={"design-three-page-one-right-picture-" + j} style={{ backgroundImage: `url(${APIdata.styles.pageone.slideshow[i].pictures[j]})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", borderRadius: 0}}></div>);
            }
            lijstslideshow.push(<div key={1000 + i}style={{textAlign: "center"}} ><div className="design-four-page-one-right-standardtext" style={{color: APIdata.styles.pageone.slideshow[i].standardtextcolor, fontFamily: APIdata.styles.pageone.slideshow[i].standardtextfont, fontSize: APIdata.styles.pageone.slideshow[i].standardtextfontsize, lineHeight: APIdata.styles.pageone.slideshow[i].standardtextlineheight}}>{APIdata.styles.pageone.slideshow[i].standardtext}</div><div className="design-four-page-one-right-text" style={{color: APIdata.styles.pageone.slideshow[i].textcolor, fontFamily: APIdata.styles.pageone.slideshow[i].textfont, fontSize: APIdata.styles.pageone.slideshow[i].textfontsize}}>{APIdata.styles.pageone.slideshow[i].text}</div>{lijstslideshowpictures}</div>)
        }
    }

              return (
                  <div> 
                  {slidewidth === true? 
<div className="design-four-page-one-right-container">
<div style={{ marginTop: APIdata.styles.pageone.margintopright}}>
</div>
<div>
{lijstslideshow[slidenumber]}
</div>
</div>
:
null}
</div>
              )
          }






    return (
        <div className="design-four-page-one-body" onClick={animationchange}>
        <div className="design-four-page-one-left-body"
        style={{
            backgroundColor: APIdata.styles.generalpage.leftcolor,
        }}>

        <div className="design-four-page-one-left-cityexplorer"
        style={{
            marginTop: APIdata.styles.generalpage.bartopheight,
            paddingTop: APIdata.styles.generalpage.citydiscoveryheight
        }}>
        <div className="design-four-page-one-left-cityexplorer-logo">
        <img src={require("../images/maps-untold-logo-small.png")} alt="logo" className="design-four-page-one-left-cityexplorer-logo-image"/>
        </div>
        <div className="design-four-page-one-left-cityexplorer-mapsuntold">
        <div className="design-four-page-one-left-cityexplorer-mapsuntold-text"
        style={{
            fontFamily: APIdata.styles.generalpage.stadsontdekkermapsuntoldfont,
            fontSize: stadsontdekkermapsuntoldfontsize,
            marginTop: stadsontdekkermapsuntoldmargintop
        }}>
        Maps Untold
        </div>
        </div>
        <div className="design-four-page-one-left-cityexplorer-line">
        </div>
        <div className="design-four-page-one-left-cityexplorer-city">
        <div className="design-four-page-one-left-cityexplorer-city-text"
        style={{
            fontFamily: APIdata.styles.generalpage.stadsontdekkertextfont,
            color: APIdata.styles.generalpage.colorone,
            fontSize: stadsontdekkersize,
            marginTop: stadsontdekkertextmargintop
        }}>
        {cityexplorertext}
        </div>
        </div>
        </div>

        <div className="design-four-page-one-left-header" 
        style={{
            fontFamily:  APIdata.styles.generalpage.headerleftfont,
            fontSize: fontheightheader,
            color: APIdata.styles.generalpage.headerleftcolor,
            animation: animationfirstpage.left.animation,
            animationName: animationfirstpage.left.animationName,
        }}>
        {firstpageheaderleft}
        </div>
        <div className="design-four-page-one-left-subheader"
        style={{
            fontFamily:  APIdata.styles.generalpage.subheaderleftfont,
            fontSize: fontheightsubheader,
            color: APIdata.styles.generalpage.subheaderleftcolor,
            lineHeight: lineheightsubheader,
            animation: animationfirstpage.left.animation,
            animationName: animationfirstpage.left.animationName,
}}>
        {firstpagesubheaderleft}

        </div>
        </div>



        <div className="design-four-page-one-right-body"
        style={{
            backgroundColor: APIdata.styles.generalpage.rightcolor,
        }}>

        <Slideshow/>

        </div>
        </div>
    )
}

export default DesignFourPageOne;