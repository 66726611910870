import React, { useContext } from "react";
import DesignThreeBarTop from "./DesignThree/DesignThreeBarTop";
import DesignThreePageOne from './DesignThree/DesignThreePageOne.jsx';
import DesignThreePageTwo from './DesignThree/DesignThreePageTwo.jsx';
import DesignThreePageThree from './DesignThree/DesignThreePageThree.jsx';
import DesignThreePageFour from './DesignThree/DesignThreePageFour.jsx';
import DesignThreePageFive from './DesignThree/DesignThreePageFive.jsx';
import DesignThreeProgressBar from "./DesignThree/DesignThreeProgressBar.jsx";
import { designThreePagecounter } from "./DesignThree/DesignThreeValues.jsx";

function DesignThree() {

    // eslint-disable-next-line
    const { designThreePage, setDesignThreePage } =  useContext(designThreePagecounter)

    return (
        <div>
        <DesignThreeBarTop/>
        {designThreePage === 1 ? <DesignThreePageOne/> : null}
        {designThreePage === 2 ? <DesignThreePageTwo/> : null}
        {designThreePage === 3 ? <DesignThreePageThree/> : null}
        {designThreePage === 4 ? <DesignThreePageFour/> : null}
        {designThreePage === 5 ? <DesignThreePageFive/> : null}
        {/* <DesignThreeProgressBar/> */}
        </div>
    )
}

export default DesignThree;