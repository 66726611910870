import React, { useContext, useState }from "react";
import { clientdatacontext } from "../LoginValues.jsx";


function RecommendationsCategoriesDisplay () {

// eslint-disable-next-line
    const { clientdata, setClientdata } = useContext(clientdatacontext);

    var categoriestatusstart = [];

    for (let i = 0; i < clientdata.categories.length; i++){ 
        categoriestatusstart.push({parentcategory: clientdata.categories[i].parentcategory, subcategorynumber: 0});
    }

    const [ categoriestatus, setCategoriestatus ] = useState(categoriestatusstart);

    function handleclickoverview(event) {
        var indexsplit = event.target.id.split(" ");
        var indexfirst = parseInt(indexsplit[0]);
        var indexsecond = parseInt(indexsplit[1]);
        var inbetweenlist = [];

        for (let i = 0; i < categoriestatus.length; i++){
            if (i === indexfirst) {
                inbetweenlist.push({parentcategory: categoriestatus[i].parentcategory, subcategorynumber: indexsecond})
            } else if (i !== indexfirst) {
                inbetweenlist.push({parentcategory: categoriestatus[i].parentcategory, subcategorynumber: categoriestatus[i].subcategorynumber})
            }}
            setCategoriestatus(inbetweenlist)
    }




    return (
        <div className="login-recommendations-categories-categories-display-container">
    
            <div className="login-recommendations-categories-categories-overview-header">Overview of categories</div>
            { clientdata.categories.length > 0 ?
            <div>
            {clientdata.categories.map((item, index)=>(
                <div key={index} className="login-recommendations-categories-categories-parentcategory-container">
                <div key={index} className="login-recommendations-categories-categories-parentcategory">
                {item.parentcategory}
                </div>

                { item.subcategories.length > 0 ?
                <div>
             {item.subcategories.map((itemtwo, indexx)=>(
                <div key={indexx+1000} className="login-recommendations-categories-categories-subcategory-container">
                 { categoriestatus[index].subcategorynumber === indexx ?
                <div onClick={handleclickoverview} id={index + " " + indexx} className="login-recommendations-categories-categories-subcategory-selected">
                {itemtwo.subcategory}
                </div>
                :
                <div onClick={handleclickoverview} id={index + " " + indexx} className="login-recommendations-categories-categories-subcategory">
                {itemtwo.subcategory}
                </div>
            }
                </div>
                ))}
                </div>
            : null
            }
                <div className="login-recommendations-categories-categories-childcategory-container">
                { clientdata.categories[index].subcategories.length > 0 ?
                <div>
                    {clientdata.categories[index].subcategories[categoriestatus[index].subcategorynumber].childcategories.map((itemtwo, indexx)=>(
                    <div key={indexx+100000} className="login-recommendations-categories-categories-childcategory">
                    {itemtwo}
                    </div>
                    ))}
                    </div>
            : null
            }
                    </div>
                </div>
            ))}    
            </div>
            : null
            }
            </div>
            )}

export default RecommendationsCategoriesDisplay